import { useMemo } from 'react';
import { useDesk, useProgramSummary } from 'src/fetches/useSWRFetch';
import { returnTermTypeString } from 'src/constants/termType';
import { TermType } from 'src/types/desking';
import { PaymentType } from 'src/types/deal';
import { useDeals } from 'src/components/context/DealsProvider';
import { useConfig } from 'src/components/context/config';

function formatPaymentValue(value: number) {
  return Math.floor(value).toLocaleString('en-US', {
    maximumFractionDigits: 0,
  });
}

function calculateSecondaryTitle(paymentRange: number | null, currentPayment: number) {
  if (!paymentRange) return '';
  return formatPaymentValue(paymentRange + currentPayment);
}

function generateTermTypeString(termType: TermType) {
  return returnTermTypeString(termType);
}

export function useMonthlyPayment() {
  const { activeDealId, deal, dealIsLoading } = useDeals();
  const { desk, deskIsLoading } = useDesk(activeDealId, 'desk', deal?.vin);
  const { programSummary, programSummaryIsError, programSummaryIsLoading } = useProgramSummary(
    activeDealId,
    !desk
  );
  const config = useConfig()!;
  const paymentsAreLoading = dealIsLoading || deskIsLoading || programSummaryIsLoading;
  const noDealPayment = !dealIsLoading && !deal;
  const paymentsAreError = !programSummary || !desk || !desk.results || desk.results.length === 0;
  const currentCellNotAvailable = programSummaryIsError && desk?.results;

  const getMonthlyPayment = () => {
    if (paymentsAreLoading || noDealPayment || currentCellNotAvailable) return '';

    if (deal.paymentType === PaymentType.Cash) {
      return `$${programSummary?.downpayment?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}`;
    }

    const currentPayment = desk.results[desk.request?.currentCell || 0]?.payment;
    const paymentTitle = formatPaymentValue(currentPayment);
    const secondaryTitle = calculateSecondaryTitle(desk.paymentRange, currentPayment);

    return `$${paymentTitle}${secondaryTitle ? ' - ' + secondaryTitle : ''}`;
  };

  const monthlyPayment = useMemo(getMonthlyPayment, [
    deal,
    desk,
    programSummary,
    paymentsAreLoading,
    noDealPayment,
    currentCellNotAvailable,
  ]);

  const monthlyPaymentTermType = useMemo(() => {
    if (paymentsAreLoading || currentCellNotAvailable) return '';
    if (!desk || !deal) return TermType.Monthly;
    if (deal.paymentType === PaymentType.Cash) return '';
    return generateTermTypeString(desk.termType);
  }, [desk, deal, paymentsAreLoading, currentCellNotAvailable]);

  const monthlyPaymentTerm = useMemo(() => {
    if (!desk || !desk.results! || desk.results.length === 0 || !deal || currentCellNotAvailable)
      return null;

    const termNumber = desk.results[desk.request?.currentCell || 0]?.term;

    if (deal.paymentType === PaymentType.Cash) {
      return 'Cash';
    }

    return `${termNumber} months`;
  }, [desk, deal, currentCellNotAvailable]);

  return {
    monthlyPayment,
    monthlyPaymentIsLoading: paymentsAreLoading || paymentsAreError || currentCellNotAvailable,
    monthlyPaymentIsHidden: config.forms?.retailing?.hidePayments || currentCellNotAvailable,
    monthlyPaymentTermType,
    monthlyPaymentTerm,
  };
}
