import toast from 'react-hot-toast';
import PageLoader from '../../../components/PageLoader';
import PageError from '../../../components/PageError';
import { useDocTitle } from '../../../helpers/global-document-title';
import { useTradeins } from '../../../fetches/useSWRFetch';
import Success from '../../../components/form/Success';
import VehicleTradeInCard from 'src/components/cards/VehicleTradeInCard';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullBlockRoute } from 'src/types/blocks';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useAuth } from 'src/components/context/AuthProvider';
import { useMemo } from 'react';
import { TradeInResponseBlock } from 'src/types/tradein-block';

export default function TradeinSuccessPage() {
  useDocTitle('Success!');
  const navigate = useNavigate();
  const location = useLocation();
  const { tradeins, tradeinsIsLoading, tradeinsIsError, tradeinsDelete } = useTradeins();
  const { token } = useAuth();

  const tradeIn = tradeins?.sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  );

  const successTradein = useMemo(() => {
    if (!tradeins) return undefined;
    // If it exists, state will be trade in id
    if (location?.state) {
      return location.state as TradeInResponseBlock;
    }

    return tradeIn[0];
  }, [tradeIn, location.state, tradeins]);

  const handleTradeInCardDelete = async (id: string) => {
    try {
      await tradeinsDelete(id);
      navigate(FullBlockRoute.TradeIn);
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  if (tradeinsIsLoading || !tradeIn || !successTradein) return <PageLoader />;
  if (tradeinsIsError) return <PageError />;

  return (
    <Success
      title="Your trade-in estimate is here!"
      subtitle="Keep in mind, this is just an estimate for now. Let's get together and see how much your vehicle is worth."
    >
      <div className="valuation-card--list">
        <VehicleTradeInCard
          key={successTradein.vin}
          tradeIn={successTradein}
          handleDelete={handleTradeInCardDelete}
          readOnly={true}
        />
      </div>

      <Link
        to={`${FullBlockRoute.TradeIn}new`}
        className="btn mod-primary mod-text-icon"
        reloadDocument={token ? true : false}
      >
        <FontAwesomeIcon icon={faPlus} className="u-icon" />
        Add another
      </Link>
    </Success>
  );
}
