import './PageLoader.css';
import { useConfig } from './context/config';

export default function PageLoader() {
  const config = useConfig()!;

  return config.isRetailing ? (
    <div className="page--main">
      <div className="page--loader">
        <div className="progress mod-loading">
          <div className="progress--bar"></div>
        </div>
      </div>
    </div>
  ) : (
    <div className="block-modal-container">
      <div className="u-display-flex">
        <div className="page--loader">
          <div className="progress mod-loading">
            <div className="progress--bar"></div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}
