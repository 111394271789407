import {
  faCommentsAltDollar as faCommentsAltDollarRegular,
  faBolt as faBoltRegular,
  faCalendarStar as faCalendarStarRegular,
  faGlasses,
  faCars,
  faCheckDouble,
  faFile,
  faCommentsAlt,
  faFlagCheckered,
} from '@fortawesome/pro-regular-svg-icons';
import { BlockRoute, DocumentItem, FullBlockRoute, RouteItem } from '../types/blocks';
import { WebsiteRoute } from '../external/WebsiteRoutes';
import { faCalendarStar, faBolt, faCommentsAltDollar } from '@fortawesome/pro-solid-svg-icons';
import { DocumentTag } from 'src/types/document-block';

export const AcccountRoute: RouteItem = {
  name: BlockRoute.Account,
  title: 'My Account',
  route: FullBlockRoute.Account,
  time: '1 min',
  icon: faCheckDouble,
  isDealDependent: false,
};

export const CreditApplicationRoute: RouteItem = {
  name: BlockRoute.CreditApplication,
  title: 'Credit App',
  widgetTitle: 'Fill out a Credit Application',
  route: FullBlockRoute.CreditApplication,
  websiteRoute: WebsiteRoute.CreditApplication,
  time: '5 min',
  icon: faCheckDouble,
  isDealDependent: false,
  parentBlock: BlockRoute.QuickForms,
};

export const TradeInRoute: RouteItem = {
  name: BlockRoute.TradeIn,
  title: 'Trade In',
  widgetTitle: 'Get Credit for Your Trade',
  route: FullBlockRoute.TradeIn,
  websiteRoute: WebsiteRoute.TradeIn,
  time: '2 min',
  icon: faCars,
  isDealDependent: false,
  parentBlock: BlockRoute.QuickForms,
};

export const AppointmentsRoute: RouteItem = {
  name: BlockRoute.Appointments,
  title: 'Book an Appointment',
  shortTitle: 'Schedule',
  widgetTitle: 'Book an Appointment',
  subtitle: 'Schedule a pick up or delivery',
  subtitleDone: 'Next: ',
  route: FullBlockRoute.Appointments,
  websiteRoute: WebsiteRoute.Appointments,
  time: '1 min',
  icon: faCalendarStarRegular,
  iconDone: faCalendarStar,
  isDealDependent: true,
};

export const DocumentsRoute: RouteItem = {
  name: BlockRoute.Documents,
  title: 'Documents',
  subtitle: 'Pre-Upload Paperwork',
  alternateTitle: 'My Docs',
  widgetTitle: 'Pre-Upload Paperwork',
  websiteRoute: WebsiteRoute.Documents,
  route: FullBlockRoute.Documents,
  time: '5 min',
  icon: faFile,
  isDealDependent: false,
  parentBlock: BlockRoute.QuickForms,
  isIndexPage: true,
};

export const DriversLicenseFrontRoute: DocumentItem = {
  name: DocumentTag.DriversLicenseFront,
  title: 'Drivers License',
  titleSpecific: 'Drivers License (front)',
  route: `${FullBlockRoute.Documents}${DocumentTag.DriversLicenseFront}`,
  parentBlock: BlockRoute.Documents,
};

export const DriversLicenseBackRoute: DocumentItem = {
  name: DocumentTag.DriversLicenseBack,
  title: 'Drivers License',
  titleSpecific: 'Drivers License (back)',
  route: `${FullBlockRoute.Documents}${DocumentTag.DriversLicenseBack}`,
  parentBlock: BlockRoute.Documents,
};

export const InsuranceRoute: DocumentItem = {
  name: DocumentTag.Insurance,
  title: 'Insurance',
  route: `${FullBlockRoute.Documents}${DocumentTag.Insurance}`,
  parentBlock: BlockRoute.Documents,
};

export const IncomeProofRoute: DocumentItem = {
  name: DocumentTag.Income,
  title: 'Income',
  route: `${FullBlockRoute.Documents}${DocumentTag.Income}`,
  parentBlock: BlockRoute.Documents,
};

export const ResidenceProofRoute: DocumentItem = {
  name: DocumentTag.Residence,
  title: 'Residence',
  route: `${FullBlockRoute.Documents}${DocumentTag.Residence}`,
  parentBlock: BlockRoute.Documents,
};

export const OtherDocumentsRoute: DocumentItem = {
  name: DocumentTag.Other,
  title: 'Other Documents',
  route: `${FullBlockRoute.Documents}${DocumentTag.Other}`,
  parentBlock: BlockRoute.Documents,
};

export const ReferencesRoute: RouteItem = {
  name: BlockRoute.References,
  title: 'References',
  subtitle: 'Enter financial references',
  alternateTitle: 'My references',
  widgetTitle: 'Add References',
  websiteRoute: WebsiteRoute.Documents,
  route: FullBlockRoute.References,
  time: '5 min',
  icon: faFile,
  isDealDependent: false,
};

export const PaymentOptionsRoute: RouteItem = {
  name: BlockRoute.PaymentOptions,
  title: 'Payment Options',
  shortTitle: 'Payment',
  subtitle: 'Explore monthly payments',
  widgetTitle: 'Explore Payment Options',
  route: FullBlockRoute.PaymentOptions,
  websiteRoute: WebsiteRoute.PaymentOptions,
  time: '5 min',
  icon: faCommentsAltDollarRegular,
  iconDone: faCommentsAltDollar,
  isDealDependent: true,
  tip: 'To get payment details, add a vehicle.',
};

export const QuickFormsRoute: RouteItem = {
  name: BlockRoute.QuickForms,
  title: 'Quick Forms',
  shortTitle: 'Apply',
  subtitle: 'Complete 3 forms, skip the wait',
  subtitleDone: 'Completed 3 Forms.',
  route: FullBlockRoute.QuickForms,
  time: '5 min',
  icon: faBoltRegular,
  iconDone: faBolt,
  isDealDependent: false,
  isIndexPage: true,
};

export const ReviewRoute: RouteItem = {
  name: BlockRoute.Review,
  title: 'Review & Confirm',
  shortTitle: 'Review',
  subtitle: 'See payment details & lock it in',
  route: FullBlockRoute.Review,
  time: '5 min',
  icon: faGlasses,
  alternateIcon: faFlagCheckered,
  alternateTitle: 'Finish',
  isDealDependent: true,
  tip: 'To get payment details, add a vehicle.',
};

export const ChatRoute: RouteItem = {
  name: BlockRoute.Chat,
  title: 'Chat',
  shortTitle: 'Chat',
  route: FullBlockRoute.Chat,
  icon: faCommentsAlt,
  isDealDependent: false,
  time: '1 min',
};

export const VehiclesRoute: RouteItem = {
  name: BlockRoute.Vehicle,
  title: 'Vehicles',
  shortTitle: 'Vehicles',
  route: FullBlockRoute.Vehicle,
  icon: faCars,
  isDealDependent: false,
  time: '1 min',
};

export const WidgetChecklist: RouteItem[] = [
  PaymentOptionsRoute,
  CreditApplicationRoute,
  TradeInRoute,
  AppointmentsRoute,
  DocumentsRoute,
];

export const WidgetJourneyTabItems: RouteItem[] = [
  PaymentOptionsRoute,
  QuickFormsRoute,
  AppointmentsRoute,
  ReviewRoute,
];

export const RouteItems: RouteItem[] = [
  CreditApplicationRoute,
  TradeInRoute,
  AppointmentsRoute,
  DocumentsRoute,
];

export const menuItems: RouteItem[] = [
  PaymentOptionsRoute,
  QuickFormsRoute,
  AppointmentsRoute,
  ReviewRoute,
];

export const quickFormsMenuItems: RouteItem[] = [
  TradeInRoute,
  CreditApplicationRoute,
  DocumentsRoute,
];

export const chatTopNavMenuItems: RouteItem[] = [
  TradeInRoute,
  CreditApplicationRoute,
  DocumentsRoute,
];

export const chatBottomNavMenuItems: RouteItem[] = [
  ChatRoute,
  VehiclesRoute,
  DocumentsRoute,
  ReviewRoute,
];

export const noAuthQuickFormsMenuItems: RouteItem[] = [TradeInRoute, CreditApplicationRoute];
