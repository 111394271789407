import { Config } from 'src/components/context/config';

export function returnUserFriendlyPrice(price?: number) {
  // If price is 0 (for example, fee items), return $0.00
  if (!price && price !== 0) return 'Loading';

  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
}

// In any of these cases, the customer's view of car price or payment on it should be hidden
export function isSitePaymentHidden(config: Config) {
  return (
    config.packages?.hideprices ||
    !config.packages?.desking ||
    config.forms?.retailing?.hidePayments
  );
}
