import Joi from 'joi';
import {
  nameRegex,
  emailRegex,
  isValidPhone,
  isValidEmail,
  isValidBirthDate,
  isValidFullSsn,
  isValidPartialSsn,
  getStateFromZip,
} from './validation';
import { DeclarationType } from './tradeinDeclaration';
import { PaymentType } from 'src/types/deal';

export const firstName = Joi.string().pattern(nameRegex).required().messages({
  'string.empty': `First name is required`,
  'string.pattern.base': `First name must contain only letters`,
});

export const middleName = Joi.string().pattern(nameRegex).allow('').messages({
  'string.pattern.base': `Middle name must contain only letters`,
});

export const lastName = Joi.string().pattern(nameRegex).required().messages({
  'string.empty': `Last name is required`,
  'string.pattern.base': `Last name must contain only letters`,
});

export const email = Joi.string().pattern(emailRegex).required().messages({
  'string.empty': `Email address is required`,
  'string.pattern.base': `Please enter a valid email address`,
});

export const phone = Joi.string()
  .custom((value, helpers) => {
    if (!isValidPhone(value.replaceAll('-', ''))) {
      return helpers.message({ custom: 'Please enter a valid phone number' });
    }
    return value;
  }, 'custom validation')
  .required()
  .messages({
    'string.empty': `Phone number is required`,
  });

export const emailOptional = Joi.custom((value, helpers) => {
  if (!isValidEmail(value) && !isValidPhone(helpers.state.ancestors[0].phone.replaceAll('-', ''))) {
    return helpers.message({ custom: 'Phone or email is required' });
  }
  if (!isValidEmail(value) && value !== '') {
    return helpers.message({ custom: 'Please enter a valid email address' });
  }
  return value;
});

export const phoneOptional = Joi.custom((value, helpers) => {
  if (!isValidPhone(value.replaceAll('-', '')) && !isValidEmail(helpers.state.ancestors[0].email)) {
    return helpers.message({ custom: 'Phone or email is required' });
  }
  if (!isValidPhone(value.replaceAll('-', '')) && value !== '') {
    return helpers.message({ custom: 'Please enter a valid phone number' });
  }
  return value;
}, 'custom validation');

export const hiddenPhone = Joi.string().allow('');

export const optoutCalls = Joi.custom((value, helpers) => {
  if (value && helpers.state.ancestors[0].optoutSms && helpers.state.ancestors[0].optoutEmails) {
    return helpers.message({ custom: 'We need at least one way to contact you' });
  }
  return value;
});

export const optoutSms = Joi.custom((value, helpers) => {
  if (value && helpers.state.ancestors[0].optoutCalls && helpers.state.ancestors[0].optoutEmails) {
    return helpers.message({ custom: 'We need at least one way to contact you' });
  }
  return value;
});

export const optoutEmails = Joi.custom((value, helpers) => {
  if (value && helpers.state.ancestors[0].optoutSms && helpers.state.ancestors[0].optoutCalls) {
    return helpers.message({ custom: 'We need at least one way to contact you' });
  }
  return value;
});

export const comments = Joi.string().allow('');

export const softPullConsent = Joi.boolean().invalid(false).required().messages({
  'string.empty': `You must consent to a credit check to continue`,
  'any.invalid': `You must consent to a credit check to continue`,
});
export const esignatureConsent = Joi.boolean().invalid(false).required().messages({
  'string.empty': `You must consent to the use of an eSignature to continue`,
  'any.invalid': `You must consent to the use of an eSignature to continue`,
});
export const esignature = Joi.string().required().messages({
  'string.empty': `Please sign your name to continue`,
});

export const location = Joi.string().required().messages({
  'string.empty': `Please select the location you are interested in`,
});

export const applicantType = Joi.string().required().messages({
  'string.empty': `Applicant Type is required`,
});

export const calendarAppointmentDate = Joi.date().required().messages({
  'string.empty': `Date is required`,
});

export const inputAppointmentDate = Joi.string().required().messages({
  'string.empty': `Date is required`,
});

export const time = Joi.string().required().messages({
  'string.empty': `Time is required`,
});

export const notes = Joi.string().allow('');

export const type = Joi.string().required().messages({
  'string.empty': `Please select the reason for your appointment`,
});

export const status = Joi.string().allow('');

export const relationship = Joi.string().pattern(nameRegex).required().messages({
  'string.empty': `Relationship is required`,
  'string.pattern.base': `Relationship must contain only letters`,
});
export const al1 = Joi.string()
  .required()
  .pattern(/^[^\s].*$/)
  .messages({
    'string.pattern.base': `Please remove any unnecessary spaces`,
    'string.empty': `Address Line 1 is required`,
  });
export const addressLine2 = Joi.string().allow('');
export const city = Joi.string()
  .required()
  .pattern(/^[^\s].*$/)
  .messages({
    'string.pattern.base': `Please remove any unnecessary spaces`,
    'string.empty': `City is required`,
  });
export const state = Joi.string().required().messages({
  'string.empty': `State is required`,
});

export const zip = Joi.string()
  .required()
  .pattern(/^\d{5}$/)
  .messages({
    'string.empty': `Zip Code is required`,
    'string.pattern.base': `Zip Code must be 5 digits`,
  });

export const zipInState = Joi.string()
  .pattern(/^\d{5}$/)
  .custom((value, helpers) => {
    if (!getStateFromZip(value)) {
      return helpers.message({ custom: 'Please enter a valid zip' });
    }
    return value;
  }, 'custom validation')
  .messages({
    'string.pattern.base': `Zip Code must be 5 digits`,
    'string.empty': `Zip is required for accurate pricing`,
  });

export const inputCode = Joi.string()
  .pattern(/^[A-Za-z0-9]{6}$/)
  .required()
  .messages({
    'string.empty': `Please enter the code we sent to your email`,
    'string.pattern.base': `Please enter the 6-character code we sent to your email`,
  });

export const birthDate = Joi.string()
  .custom((value, helpers) => {
    if (!isValidBirthDate(value)) {
      return helpers.message({
        custom: 'Please enter a valid birth date in the format MM/DD/YYYY',
      });
    }
    return value;
  }, 'custom validation')
  .required()
  .messages({
    'string.empty': `Date of Birth is required`,
    custom: `Please enter a valid birth date in the format MM/DD/YYYY`,
  });

export const ssnFull = Joi.string()
  .custom((value, helpers) => {
    if (!isValidFullSsn(value)) {
      return helpers.message({ custom: 'This number must be 9 digits long' });
    }
    return value;
  }, 'custom validation')
  .required()
  .messages({
    'string.empty': `This field is is required`,
  });

export const ssnPartial = Joi.string()
  .custom((value, helpers) => {
    if (!isValidPartialSsn(value)) {
      return helpers.message({ custom: 'Invalid number' });
    }
    return value;
  }, 'custom validation')
  .required()
  .messages({
    'string.empty': `This field is is required`,
  });

export const driversLicenseNumber = Joi.string().required().messages({
  'string.empty': `Driver's License Number is required`,
});

export const driversLicenseState = Joi.string().required().messages({
  'string.empty': `Driver's License State is required`,
});

export const appointmentVin = Joi.string().allow('', null);
export const blockId = Joi.string().allow('', null);

export const tradeinDeclaration = Joi.string().allow(DeclarationType).required().messages({
  'string.empty': `You need to select an option to continue`,
});

export const paymentType = Joi.string()
  .allow(...Object.values(PaymentType))
  .required()
  .messages({
    'string.empty': `You need to select a payment type to continue`,
  });

export const communicationSchema = {
  firstName,
  lastName,
  email,
  phone,
  optoutCalls,
  optoutSms,
  optoutEmails,
};

export const optionalCommunicationSchema = {
  firstName,
  lastName,
  email: emailOptional,
  phone: phoneOptional,
  optoutCalls,
  optoutSms,
  optoutEmails,
};

export const optionalEmailSchema = {
  firstName,
  lastName,
  email: emailOptional,
  phone,
  optoutCalls,
  optoutSms,
  optoutEmails,
};

export const optionalPhoneSchema = {
  firstName,
  lastName,
  email,
  phone: phoneOptional,
  optoutCalls,
  optoutSms,
  optoutEmails,
};

export const referenceSchema = {
  firstName,
  lastName,
  email,
  phone,
  relationship,
};

export const partialReferenceSchema = {
  firstName,
  lastName,
  email: emailOptional,
  phone,
  relationship,
};

export const preapprovalSchema = {
  firstName,
  lastName,
  email: emailOptional,
  phone: phoneOptional,
  optoutCalls,
  optoutSms,
  optoutEmails,
  zip,
};

export const paymentTypeSchema = {
  firstName,
  middleName,
  lastName,
  email,
  phone,
};

export const appointmentSchema = {
  id: blockId,
  calendarAppointmentDate,
  inputAppointmentDate,
  time,
  notes,
  type,
  status,
  vin: appointmentVin,
};

export const addressBaseSchema = {
  al1,
  addressLine2,
  city,
  state,
  zip: zipInState,
};

export const addressSchema = {
  ...addressBaseSchema,
  phone: hiddenPhone,
};

export const employerAddressSchema = {
  ...addressSchema,
  phone,
};

export const paymentSensitiveFullSchema = {
  birthDate,
  driversLicenseNumber,
  driversLicenseState,
  ssn: ssnFull,
};

export const paymentSensitivePartialSchema = {
  birthDate,
  driversLicenseNumber,
  driversLicenseState,
  ssn: ssnPartial,
};
