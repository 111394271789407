/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Select } from '../controls/Select';
import StateDropdownArray from '../dropdowns/StateDropdownArray';
import { DateOfBirth, DLNumber, SSN } from '../inputs/Input';
import { useConfig } from '../context/config';
import { paymentSensitiveFullSchema, paymentSensitivePartialSchema } from 'src/constants/schemas';
import { FormBody, useFormSubmit } from '../form/Form';
export const PaymentSensitiveGroup = () => {
  const { activeForm, handleFormGroupSubmit, formValues: payment } = useFormSubmit();
  const config = useConfig()!;
  const form = useForm({
    resolver: joiResolver(
      Joi.object(payment.id ? paymentSensitivePartialSchema : paymentSensitiveFullSchema)
    ),
    mode: 'onBlur',
    defaultValues: {
      // to do: allow SSN last four editing (this will reset to server value if user advances and then returns)
      ssn: payment.ssn,
      birthDate: payment.birthDate,
      driversLicenseNumber: payment.driversLicenseNumber,
      driversLicenseState: payment.driversLicenseState,
    },
  });

  if (activeForm !== 'paymentSensitiveForm') {
    return null;
  }

  return (
    <form id="paymentSensitiveForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <FormBody
        title="Provide your basic information"
        body="The personal information you provide will be securely kept and will only be used to process your loan application."
      />
      <DateOfBirth control={form.control} />
      <SSN
        control={form.control}
        label={config.forms?.creditApplication?.ssnLabel || 'Social Security Number'}
      />
      <div className="u-display-grid grid--50">
        <DLNumber control={form.control} />
        <Select
          control={form.control}
          name="driversLicenseState"
          label="DL State"
          selectOptions={StateDropdownArray}
        />
      </div>
    </form>
  );
};
