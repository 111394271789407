import { useState, useEffect, createContext, useContext, useMemo } from 'react';
import { API_URL } from '../../constants/env';
import { Dealership } from '../../types/dealership';
import {
  OnMessageFn,
  WidgetForm,
  WidgetSuccessConfirmation,
  WidgetToDoConfig,
} from 'src/widgets/SpaceWidget';
import { ChatOptions } from 'src/types/chatOptions';

export interface Config extends Dealership {
  portalDomain: string;
  form?: WidgetForm;
  hostGlobal: typeof globalThis;
  routes?: WidgetToDoConfig;
  isModal: boolean;
  hostMessage?: OnMessageFn;
  redirectUrl: string;
  location?: string; // A fixed location set by multilocation widget
  chatOptions?: ChatOptions;
  formConfirmation?: WidgetSuccessConfirmation;
}

export const ConfigContext = createContext<Config | null>(null);
const LOCALHOST_HOSTNAME = 'localhost';

export function useConfigState({
  portalDomain = '',
  form = undefined,
  hostGlobal = globalThis,
  routes = undefined,
  isModal = false,
  hostMessage = undefined,
  location = undefined,
  chatOptions = undefined,
  confirmation = undefined,
  hideLocations = undefined,
}: {
  portalDomain?: string;
  form?: WidgetForm;
  hostGlobal?: typeof globalThis;
  routes?: WidgetToDoConfig;
  isModal?: boolean;
  location?: string;
  hostMessage?: OnMessageFn;
  chatOptions?: ChatOptions;
  confirmation?: WidgetSuccessConfirmation;
  hideLocations?: string[];
}): Config | null {
  const [config, setConfig] = useState<Config | null>(null);
  const redirectOrigin = getRedirectOrigin(portalDomain, config?.websiteDomain);
  const websiteOrigin = `https://${config?.websiteDomain}`;

  const modifiedRoutes = useMemo(() => {
    let modifiedRoutes = routes || {};
    for (const key in modifiedRoutes) {
      if (modifiedRoutes[key].route) {
        modifiedRoutes[key].route = websiteOrigin + modifiedRoutes[key].route;
      }
    }
    return modifiedRoutes;
  }, [routes, websiteOrigin]);

  useEffect(() => {
    let mounted = true;

    Promise.all([fetch(`${API_URL}/config`).then(res => res.json()) as Promise<Dealership>]).then(
      ([dynamicConfig]) => {
        const config: Config = {
          hideLocations:
            dynamicConfig.hideLocations !== undefined ? dynamicConfig.hideLocations : hideLocations,
          portalDomain,
          ...dynamicConfig,
          baseApi: API_URL,
          // If portalDomain is empty, we are in standalone retailing context
          isRetailing: portalDomain === '',
          // locations: locations,
          // packages: {
          //   desking: false,
          //   hideprices: false,
          //   retailing: true,
          //   crm: true,
          //   ims: true,
          //   daisi: true,
          //   sms: true,
          // },
          chatOptions,
          form,
          hostGlobal,
          isModal,
          hostMessage,
          routes: modifiedRoutes,
          redirectUrl: redirectOrigin,
          location,
          formConfirmation: confirmation,
        };
        if (mounted) {
          setConfig(config);
        }
      }
    );

    return function cleanup() {
      mounted = false;
    };
  }, [
    portalDomain,
    form,
    hostGlobal,
    modifiedRoutes,
    isModal,
    hostMessage,
    redirectOrigin,
    location,
    chatOptions,
    confirmation,
    hideLocations,
  ]);

  return config;
}

export function useConfig() {
  const config = useContext(ConfigContext);
  return config;
}

export function getRedirectOrigin(widgetDomain: string, websiteDomain?: string) {
  // Widget redirects
  if (widgetDomain !== '') {
    const { hostname } = new URL(widgetDomain);
    const redirectOrigin =
      hostname === LOCALHOST_HOSTNAME ? 'http://localhost:3000' : `https://portal.${websiteDomain}`;
    return redirectOrigin;
  }

  // eslint-disable-next-line no-undef
  const hostDomain = globalThis?.location?.hostname ?? LOCALHOST_HOSTNAME;
  return hostDomain === LOCALHOST_HOSTNAME ? 'http://localhost:3000' : `https://${websiteDomain}`;
}
