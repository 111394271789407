// /* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import {
  communicationSchema,
  optionalCommunicationSchema,
  optionalEmailSchema,
  optionalPhoneSchema,
} from '../../constants/schemas';
import { Customer } from './CustomerGroup';
import { FormBody, useFormSubmit } from '../form/Form';

interface InfoCommunicationGroupProps {
  requirePhone: boolean;
  requireEmail: boolean;
}

export const InfoCommunicationGroup = ({
  requirePhone,
  requireEmail,
}: InfoCommunicationGroupProps) => {
  const { handleFormGroupSubmit, formValues, activeForm } = useFormSubmit();
  // If we are in retailing, do NOT require phone number
  let schema;
  if (requirePhone && requireEmail) {
    schema = communicationSchema;
  } else if (requirePhone && !requireEmail) {
    schema = optionalEmailSchema;
  } else if (!requirePhone && requireEmail) {
    schema = optionalPhoneSchema;
  } else {
    schema = optionalCommunicationSchema;
  }

  const form = useForm({
    resolver: joiResolver(Joi.object(schema)),
    mode: 'onBlur',
    defaultValues: {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      phone: formValues.phone,
      optoutCalls: formValues.optoutCalls,
      optoutSms: formValues.optoutSms,
      optoutEmails: formValues.optoutEmails,
    },
  });

  if (activeForm !== 'infoCommunicationForm') {
    return null;
  }

  return (
    <form id="infoCommunicationForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <FormBody
        title="Provide your basic information"
        body="The personal information you provide will be securely kept and will only be used to process your loan application."
      />
      <Customer form={form} />
    </form>
  );
};
