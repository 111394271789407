import { faAngleDown, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import Button from '../form/Button';
import { useMemo } from 'react';
import { useConfig } from '../context/config';
import { SelectorAccordionSearch } from './SelectorAccordionSearch';

export function SelectorAccordion({
  items,
  selectedItem,
  setSelectedItem,
  renderRow,
  itemKey,
  isOpen,
  toggleOpen,
}) {
  const config = useConfig()!;
  const isVehicleSearchEnabled = !config.isRetailing;

  const openAccordionHeight = useMemo(() => {
    let height = 64;

    if (isOpen) {
      height += items.length * 65;
    }

    if (isVehicleSearchEnabled && isOpen) {
      height += 32;
    }

    return height;
  }, [items.length, isOpen, isVehicleSearchEnabled]);

  const selectedRow = useMemo(() => {
    return renderRow(
      selectedItem,
      () => {
        toggleOpen();
      },
      isOpen
    );
  }, [selectedItem, renderRow, toggleOpen, isOpen]);

  return (
    <div
      className={`selector--accordion ${isOpen ? 'is-active' : ''}`}
      style={{ height: `${openAccordionHeight}px` }}
    >
      {(items.length > 0 || isVehicleSearchEnabled) && (
        <Button
          type="button"
          text=""
          baseClass="icon-btn selector--accordion--btn"
          icon={true}
          iconName={isOpen ? faAngleDown : faAngleRight}
          isSubmitting={false}
          progress={true}
          handleSubmit={toggleOpen}
        />
      )}
      <div>
        <div key={selectedRow.vin} className="selector">
          {selectedRow}
        </div>
        {isOpen && (
          <div className="selector--accordion--menu">
            {items.map(item => (
              <div key={itemKey(item)} className="selector">
                {renderRow(item, () => {
                  setSelectedItem(item);
                  toggleOpen();
                })}
              </div>
            ))}
            <SelectorAccordionSearch />
          </div>
        )}
      </div>
    </div>
  );
}
