import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import ValuationSelector from '../selectors/ValuationSelector';
import { FullBlockRoute } from '../../types/blocks';
import { TradeinBlock, TradeInResponseBlock } from '../../types/tradein-block';
import { returnVehiclePhoto } from 'src/types/vehicle';
import ActionMenu from '../floating/popover/ActionMenu';
import { useAuth } from '../context/AuthProvider';
import { LoginState } from '../authentication/LoginForm';

interface VehicleTradeInCardProps {
  tradeIn: TradeinBlock | TradeInResponseBlock;
  handleDelete: (id: string) => Promise<void>;
  readOnly?: boolean;
}

export default function VehicleTradeInCard({
  tradeIn,
  handleDelete,
  readOnly = false,
}: VehicleTradeInCardProps) {
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { token } = useAuth();

  const confirmDelete = useCallback(() => {
    setIsLoading(true);
    handleDelete(tradeIn.id).finally(() => {
      setIsLoading(false);
    });
  }, [handleDelete, tradeIn.id]);

  // Disable item if no ucgvehicleid
  const isDisabled = tradeIn.ucgvehicleid === null;

  const renderClass = useMemo(() => {
    const classes = ['selector mod-trade'];

    if (isLoading) {
      classes.push('is-processing');
    }

    if (isDisabled) {
      classes.push('is-disabled');
    }
    return classes.join(' ');
  }, [isLoading, isDisabled]);

  return (
    <div className="u-min-width-0">
      <div className={renderClass} ref={containerRef}>
        <ValuationSelector
          to={token ? `${FullBlockRoute.TradeIn}${tradeIn.id}` : '/login'}
          img={returnVehiclePhoto(tradeIn)}
          title={`${tradeIn.year} ${tradeIn.make} ${tradeIn.model}`}
          subtitle={tradeIn.trim}
          min={tradeIn.minTrade || null}
          max={tradeIn.maxTrade || null}
          average={tradeIn.averageTrade || null}
          handleClick={e => {
            e?.preventDefault();
            if (token) {
              navigate(`${FullBlockRoute.TradeIn}${tradeIn.id}`);
            } else {
              navigate('/login', { state: LoginState.Verify });
            }
          }}
        >
          {token && !readOnly ? (
            <ActionMenu
              containerRef={containerRef}
              isSubmitting={isLoading}
              menuItems={[
                {
                  icon: faSync,
                  text: 'Edit Vehicle',
                  onClick: () => navigate(`${FullBlockRoute.TradeIn}${tradeIn.id}`),
                },
                {
                  icon: faMinusCircle,
                  text: 'Remove Vehicle',
                  onClick: confirmDelete,
                  confirmModal: {
                    title: 'Are you sure?',
                    body: 'Removing this vehicle may also delete some information attached to this deal. This will also remove any existing offers attached to this deal.',
                  },
                },
              ]}
            />
          ) : null}
        </ValuationSelector>
      </div>
    </div>
  );
}
