import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { BlockRoute, BlockStatus } from 'src/types/blocks';
import { NavigationContext } from '../NavigationProvider';

export function useMenuStatus(name: BlockRoute, className: string) {
  const { status } = useContext(NavigationContext);
  const { block } = useParams();

  const isActive = useMemo(() => {
    return (
      name === block ||
      (name === BlockRoute.QuickForms &&
        (block === BlockRoute.CreditApplication ||
          block === BlockRoute.TradeIn ||
          block === BlockRoute.Documents ||
          block === BlockRoute.References)) ||
      (name === BlockRoute.Documents && block === BlockRoute.References)
    );
  }, [block, name]);

  const renderClass = useMemo(() => {
    const classes = [className];

    // match for deal dependent routes
    if (isActive) {
      classes.push('is-active');
    }

    if (status[name] === BlockStatus.Done) {
      classes.push('is-done');
    }

    if (status[name] === BlockStatus.Incomplete) {
      classes.push('is-alert');
    }

    if (status[name] === BlockStatus.Disabled) {
      classes.push('is-disabled');
    }

    return classes.join(' ');
  }, [status, isActive, className, name]);

  return { isActive, renderClass, status: status[name] };
}
