import './NavSelector.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface NavSelectorProps {
  title: string;
  btnIcon: IconDefinition;
  onBtnClick: () => void;
}

export default function NavSelector({ title, btnIcon, onBtnClick }: NavSelectorProps) {
  return (
    <button className="nav-selector" type="button" onClick={onBtnClick}>
      <FontAwesomeIcon
        icon={btnIcon}
        className="nav-selector--icon"
        transform={{ flipX: btnIcon === faPhone ? true : undefined }}
      />
      <div className="nav-selector--title">{title}</div>
      <FontAwesomeIcon icon={faAngleRight} className="nav-selector--arrow" />
    </button>
  );
}
