import React, { RefObject, useState } from 'react';
import { ContextItem, Popover, PopoverContent, PopoverTrigger } from './Popover';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '../dialog/Dialog';
import ConfirmModal from '../dialog/ConfirmModal';

interface ActionMenuProps {
  containerRef: RefObject<HTMLDivElement> | null;
  isSubmitting: boolean;
  menuItems: {
    icon: any;
    text: string;
    onClick: () => void;
    confirmModal?: { title: string; body?: string };
  }[];
}

const ActionMenu: React.FC<ActionMenuProps> = ({ containerRef, menuItems, isSubmitting }) => {
  const [confirmModal, setConfirmModal] = useState(false);
  return (
    <>
      <Popover>
        <PopoverTrigger className="selector--btn icon-btn">
          <FontAwesomeIcon icon={faEllipsisH} />
        </PopoverTrigger>
        <PopoverContent className="popover-context-menu" containerRef={containerRef}>
          {menuItems.map((item, index) => (
            <button
              key={index}
              type="button"
              onClick={() => {
                if (item.confirmModal) {
                  setConfirmModal(true);
                } else {
                  item.onClick();
                }
              }}
            >
              <ContextItem icon={item.icon} text={item.text} />
            </button>
          ))}
        </PopoverContent>
      </Popover>
      {menuItems.map((item, index) => (
        <Dialog
          key={index}
          open={confirmModal && !!item.confirmModal}
          onOpenChange={setConfirmModal}
        >
          <ConfirmModal
            headerText={item.confirmModal?.title || ''}
            bodyText={item.confirmModal?.body || ''}
            isSubmitting={isSubmitting}
            containerRef={containerRef}
            confirmAction={item.onClick}
          />
        </Dialog>
      ))}
    </>
  );
};

export default ActionMenu;
