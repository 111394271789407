import AppointmentCard from 'src/components/cards/AppointmentCard';
import ModularVehicleCard from 'src/components/cards/vehicle/ModularVehicleCard';
import { ScheduleProvider } from 'src/components/context/ScheduleProvider';
import { useConfig } from 'src/components/context/config';
import { FormContentWrapper, FormWrapper, useSpaceForm } from 'src/components/form/Form';
import { FormActions } from 'src/components/form/FormActions';
import { FormHeader } from 'src/components/form/FormHeader';
import { WebsiteRoute } from 'src/external/WebsiteRoutes';
import { useVehicleVdp } from 'src/fetches/useSWRFetch';
import { AppointmentBlock } from 'src/types/appointment-block';
import { SingleDealResponse } from 'src/types/deal';

export function Directions({
  deal,
  appointment,
}: {
  deal: SingleDealResponse;
  appointment: AppointmentBlock;
}) {
  const { vehicleVdp } = useVehicleVdp(deal.vin);
  const config = useConfig()!;
  const activeForm = 'directions';

  const handleAsyncSelect = async () => {
    window.open(vehicleVdp?.url);
  };

  const handleNext = () => {
    window.open(`https://${config.websiteDomain}${WebsiteRoute.Contact}`);
  };

  const form = useSpaceForm({}, {});

  return (
    <FormWrapper
      handleFormSubmit={async () => handleNext()}
      handleFormCancel={() => {}}
      steps={[activeForm]}
    >
      <FormHeader title="Congratulations!" />
      <FormContentWrapper form={form} id={activeForm}>
        <div className="text--title-1">You just saved 3 hours at the dealership. 🎉</div>

        <ModularVehicleCard item={deal} status={deal.status} handleSelect={handleAsyncSelect} />
        <div>
          <div className="text--title-1">We can't wait to see you!</div>
          <div className="text--body-1">
            Visit our store to finalize your purchase and pick up your vehicle
          </div>
        </div>
        <ScheduleProvider location={appointment.location}>
          <AppointmentCard key={appointment.id} appointment={appointment} isTask={true} />
        </ScheduleProvider>
      </FormContentWrapper>
      <FormActions hideBackButton={true} fullButton={true} alternateButtonText="Get Directions" />
    </FormWrapper>
  );
}
