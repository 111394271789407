import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import ProfileSuccessPage from './ProfileSuccessPage';
import { BlockRoute } from 'src/types/blocks';
import { CustomerFormattedBlock, formatCustomerAddonBlock } from 'src/types/lead';
import { CustomerBlock } from 'src/types/customer-block';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import { Customer } from 'src/types/customer';
import { LeadFormBuilder } from '../deal/appointments/LeadFormBuilder';

interface BasicInfoFormProps {
  me: Customer;
  meUpsert: any;
}

export function BasicInfoForm({ me, meUpsert }: BasicInfoFormProps) {
  const [, setIsSubmitting] = useState(false);
  const [isSuccessState, setIsSuccessState] = useState(false);
  const { navigatePrevBlock } = useContext(NavigationContext);

  const handleFormGroupSubmit = async (data: CustomerBlock) => {
    const customer: CustomerFormattedBlock = formatCustomerAddonBlock(data);

    try {
      await meUpsert(customer);
      toast.success('Information saved');

      setIsSuccessState(true);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBack = () => {
    navigatePrevBlock(BlockRoute.Account);
  };

  if (isSuccessState) {
    return <ProfileSuccessPage />;
  }

  return (
    <LeadFormBuilder
      configuration={{
        handleFormSubmit: handleFormGroupSubmit,
        handleFormCancel: handleBack,
        additionalFields: undefined,
        navigation: <div />,
        customer: me,
        vehicle: null,
        title: 'Provide your basic information',
        body: 'The personal information you provide will be securely kept and will only be used to process your loan application.',
        submitText: 'Next',
        requirePhone: true,
        requireZip: false,
        hideComments: true,
        requireLocation: false,
        hideBackButton: true,
      }}
    />
  );
}
