import {
  returnVehiclePhoto,
  returnVehiclePrice,
  returnVehicleYearMakeModel,
} from 'src/types/vehicle';
import { AccordionProvider } from '../context/AccordionProvider';
import SelectorRow from '../selectors/SelectorRow';
import { VehicleSelectorAccordion } from '../selectors/VehicleSelectorAccordion';
import { Colors } from 'src/types/colors';
import { UseFormReturn } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfig } from '../context/config';
import { VehicleCardImage } from '../cards/vehicle/VehicleCardImage';
import { FullBlockRoute } from 'src/types/blocks';
import { useSessionContext } from '../context/SessionProvider';
import { SingleDealResponse, UnauthorizedDealResponse } from 'src/types/deal';
import { useVehicleVdp, useVehicleVinSearch } from 'src/fetches/useSWRFetch';
import { useAuth } from '../context/AuthProvider';

export enum VehicleSelectionMode {
  Add = 'add', // Add to unauthorized "deal"
  Browse = 'browse', // Browse VDP of vehicle
  Select = 'select', // Select for appointment
  Chat = 'chat', // Chat with dealer about vehicle
}

export function VehicleSelectionWithVinSearch({ vin }) {
  const { searchVehicle } = useVehicleVinSearch(vin);

  if (!searchVehicle) {
    return null;
  }

  return <VehicleSelection initialVehicle={searchVehicle} mode={VehicleSelectionMode.Add} />;
}

export function VehicleSelection({
  form,
  initialVehicle,
  mode,
  handleSubmit,
  isActive,
}: {
  form?: UseFormReturn<any>;
  initialVehicle: SingleDealResponse | UnauthorizedDealResponse;
  mode: VehicleSelectionMode;
  handleSubmit?: any;
  isActive?: boolean;
}) {
  const location = useLocation();
  const config = useConfig()!;
  const { setSessionVehicle } = useSessionContext();
  const navigate = useNavigate();
  const { token } = useAuth();
  const price = token
    ? initialVehicle?.sellingPrice ?? initialVehicle?.price
    : initialVehicle?.price ?? (initialVehicle as UnauthorizedDealResponse)?.msrp;
  const { vehicleVdp } = useVehicleVdp(initialVehicle?.vin);

  // If no vehicle is known and we aren't in retailing mode, return null
  if (initialVehicle === null && !config.isRetailing) {
    return null;
  }

  // If a vehicle is expected, wait for the search vehicle to return.
  if (initialVehicle && !initialVehicle?.vin) {
    return null;
  }

  if (
    (!initialVehicle || initialVehicle?.vin === null) &&
    !location.pathname.includes('appointments/new')
  ) {
    return null;
  }

  if (mode !== VehicleSelectionMode.Select && initialVehicle) {
    return (
      <div className="selector--row-link--rounded-list">
        <SelectorRow
          thumbnail={{
            element: (
              <VehicleCardImage
                imageSrc={returnVehiclePhoto(initialVehicle)} // Gets the vehicle image as provided by the website on launch
                className="selector--img"
              />
            ),
            isWide: true,
          }}
          title={returnVehicleYearMakeModel(initialVehicle)}
          subtitle={initialVehicle.trim}
          handleRowClick={async () => {
            switch (mode) {
              case VehicleSelectionMode.Chat:
                handleSubmit(initialVehicle.vin, 'payments');
                break;
              case VehicleSelectionMode.Browse:
                window.open(vehicleVdp?.url);
                break;
              case VehicleSelectionMode.Add:
                setSessionVehicle({ vin: initialVehicle.vin });
                navigate(FullBlockRoute.PaymentOptions, { replace: true }); // refresh page so sessionStorage changes take effect
                break;
              default:
                return;
            }
          }}
          isActive={isActive}
          isWideGraphic={true}
          label={[
            {
              label: returnVehiclePrice(price ?? null) || 'Price Not Available',
              labelColor: Colors.White,
            },
          ]}
          labelColor={Colors.White}
        />
      </div>
    );
  }

  // Editable multi-select vehicle selector
  return (
    <AccordionProvider>
      <VehicleSelectorAccordion form={form} initialVehicle={initialVehicle} />
    </AccordionProvider>
  );
}
