import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';
import { Label } from '../text/Label';
import './Selector.css';
import { Colors } from 'src/types/colors';
import { SelectorText } from './SelectorText';
import { Thumbnail, ThumbnailProps } from './Thumbnail';

interface ButtonOnlySelectorProps {
  thumbnail?: ThumbnailProps;
  alt?: string;
  title?: string;
  subtitle?: string;
  label?: string;
  labelColor?: Colors;
  btnIcon?: any;
  onBtnClick: (event: any) => void;
  children?: React.ReactNode;
}

function ButtonOnlySelector(
  {
    thumbnail,
    title,
    subtitle,
    label,
    labelColor,
    btnIcon,
    onBtnClick,
    children,
  }: ButtonOnlySelectorProps,
  ref
) {
  return (
    <>
      <div className="selector--white-container">
        <Thumbnail thumbnail={thumbnail} />
        <SelectorText title={title} subtitle={subtitle} />
        {label && <Label color={labelColor || Colors.Blue2} text={label} />}
      </div>

      {children ? (
        children
      ) : (
        <button type="button" onClick={onBtnClick} className="selector--btn icon-btn" ref={ref}>
          <FontAwesomeIcon icon={btnIcon} className="" />
        </button>
      )}
    </>
  );
}

export default forwardRef(ButtonOnlySelector);
