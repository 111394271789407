import { useContext, useState } from 'react';
import Confetti from 'react-confetti-boom';
import toast from 'react-hot-toast';
import { useAuth } from 'src/components/context/AuthProvider';
import { useDeals } from 'src/components/context/DealsProvider';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { Review } from 'src/components/desking/Review';
import { FormBody } from 'src/components/form/Form';
import { FormBasicPage } from 'src/components/form/FormShell';
import { useApiFetch } from 'src/fetches/useApiFetch';
import { useDesk, useSwrDeals } from 'src/fetches/useSWRFetch';
import { BlockRoute } from 'src/types/blocks';
import { Colors } from 'src/types/colors';

export function ReviewAndConfirm() {
  const apiFetch = useApiFetch();
  const { dealsMutate } = useSwrDeals();
  const { deal, activeDealId, dealMutate } = useDeals();
  const { deskMutate } = useDesk(activeDealId, 'desk', deal?.vin);
  const [isReviewed, setIsReviewed] = useState(false);
  const { nextBlockTitle } = useContext(NavigationContext);
  const { token } = useAuth();
  const {
    sessionCustomer,
    sessionDesk,
    setSessionDesk,
    unauthorizedLockedDeals,
    setUnauthorizedLockedDeals,
  } = useSessionContext();

  // Lock the deal
  const handleAuthenticatedSubmit = async () => {
    const path = `/deals/${activeDealId}/desk-state-lock`;
    const requestOptions = {
      method: 'POST',
    };

    try {
      await apiFetch(path, requestOptions);
      await Promise.all([dealMutate(), dealsMutate(), deskMutate()]);

      toast.success('Deal locked');
      setIsReviewed(true);
    } catch (e: any) {
      console.log(e.message);
      // For now, give generic error message
      toast.error('Something went wrong. Please try again later.');
    }
  };

  // Lock the deal
  const handleUnauthenticatedSubmit = async () => {
    const path = `/unauthorized/desk-state-lock`;

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        customer: sessionCustomer,
        vin: deal?.vin,
      }),
    };

    try {
      const desk = await apiFetch(path, requestOptions);
      setSessionDesk({ ...sessionDesk, [deal?.vin as string]: desk });
      if (unauthorizedLockedDeals && !unauthorizedLockedDeals.includes(deal?.vin as string)) {
        setUnauthorizedLockedDeals([...unauthorizedLockedDeals, deal?.vin as string]);
      }

      toast.success('Deal locked');
      setIsReviewed(true);
    } catch (e: any) {
      console.log(e.message);
      // For now, give generic error message
      toast.error('Something went wrong. Please try again later.');
    }
  };

  const handleSubmit = async () => {
    if (token) {
      await handleAuthenticatedSubmit();
    } else {
      await handleUnauthenticatedSubmit();
    }
  };

  return !isReviewed ? (
    <FormBasicPage
      configuration={{
        blockRoute: BlockRoute.Review,
        alternateButtonText: nextBlockTitle,
        formNavigationClass: 'block-container--fixed-nav mod-mobile-only',
        hideBackButton: !token,
        hidePrimaryButton: !token,
      }}
    >
      <>
        <FormBody
          title="Review & Confirm"
          body="Check your selections and swipe to secure your vehicle with confidence. Your new adventure awaits!"
        />
        <div className="divider" />

        <Review handleSuccess={handleSubmit} />
      </>
    </FormBasicPage>
  ) : (
    <Confetti
      mode={'boom'}
      x={0.5}
      y={0.5}
      particleCount={120}
      deg={270}
      shapeSize={10}
      spreadDeg={45}
      effectInterval={2000}
      effectCount={3}
      colors={[Colors.Yellow1, Colors.Blue1, Colors.Red1, '#fff9b0', '#5856D6']}
    />
  );
}
