import { AppointmentStatus } from 'src/types/appointment';

export function returnLabelText(status: AppointmentStatus) {
  switch (status) {
    case AppointmentStatus.Set:
      return 'Action Required';
    case AppointmentStatus.Confirmed:
      return 'Confirmed';
    case AppointmentStatus.Completed:
      return 'Completed';
    case AppointmentStatus.Missed:
      return 'Missed';
    case AppointmentStatus.Declined:
      return 'Declined';
    default:
      return 'Unknown';
  }
}
