import { useCallback, useRef, useState } from 'react';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCloudUpload, faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import { allowedImageExtensions, uploadEnum } from '../../constants/files';
import Selector from '../selectors/Selector';
import { getFormattedDate } from 'src/constants/dates';
import { Colors } from 'src/types/colors';
import { useAuth } from '../context/AuthProvider';
import ActionMenu from '../floating/popover/ActionMenu';
import { LoginState } from '../authentication/LoginForm';
import { FullBlockRoute } from 'src/types/blocks';

interface FileManageCardProps {
  id: string;
  imgSrc: string;
  tag: string;
  handleDelete: (id: string, imgSrc?: string) => Promise<void>;
  name?: string;
  updatedAt?: string;
}

export default function FileManageCard({
  id,
  imgSrc,
  tag,
  handleDelete,
  name,
  updatedAt,
}: FileManageCardProps) {
  const [isLoading, setIsLoading] = useState(false);
  const jsDate = updatedAt ? new Date(updatedAt) : undefined;
  const formattedDate = jsDate ? getFormattedDate(jsDate) : undefined;
  const { token } = useAuth();
  const lastDotIndex = imgSrc.lastIndexOf('.');
  const lastQueryIndex = imgSrc.lastIndexOf('?');
  const extensionEndIndex = lastQueryIndex === -1 ? imgSrc.length : lastQueryIndex;
  const containerRef = useRef(null);
  const fileType = imgSrc.substring(lastDotIndex, extensionEndIndex);
  const hasImgThumbnail = allowedImageExtensions.includes(fileType);

  const confirmDelete = useCallback(() => {
    setIsLoading(true);

    handleDelete(id, imgSrc).finally(() => {
      setIsLoading(false);
    });
  }, [handleDelete, id, imgSrc]);

  return (
    <div>
      <div className={isLoading ? 'selector is-processing' : 'selector'}>
        <Selector
          to={token ? `${FullBlockRoute.Documents}${tag}` : '/login'}
          title={name}
          subtitle={jsDate === undefined ? undefined : formattedDate}
          thumbnail={
            token
              ? {
                  icon: imgSrc ? null : faCloudUpload,
                  element: hasImgThumbnail ? (
                    <img className="selector--img" src={imgSrc} alt="" />
                  ) : null,
                  isWide: true,
                }
              : undefined
          }
          label={uploadEnum[tag].label}
          labelColor={Colors.Blue2}
          linkState={LoginState.Verify}
          btnIcon={faEllipsisH}
        >
          {token ? (
            <ActionMenu
              containerRef={containerRef}
              isSubmitting={isLoading}
              menuItems={[
                {
                  icon: faMinusCircle,
                  text: 'Remove File',
                  onClick: confirmDelete,
                  confirmModal: {
                    title: 'Are you sure?',
                    body: `You won't be able to recover this file.`,
                  },
                },
              ]}
            />
          ) : null}
        </Selector>
      </div>
    </div>
  );
}
