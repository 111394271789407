import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Colors } from 'src/types/colors';

export interface ThumbnailProps {
  icon?: any;
  iconBackground?: Colors;
  color?: Colors;
  element?: React.ReactNode;
  isWide?: boolean;
}

export function Thumbnail({ thumbnail }: { thumbnail?: ThumbnailProps }) {
  if (!thumbnail) {
    return null;
  }

  return (
    <div
      className={`selector--icon--holder ${thumbnail.isWide ? 'mod-wide' : ''}`}
      style={{ backgroundColor: thumbnail.iconBackground ?? 'inherit' }}
    >
      {thumbnail.element ? (
        thumbnail.element
      ) : (
        <FontAwesomeIcon
          icon={thumbnail.icon}
          className="selector--icon"
          color={thumbnail.color || Colors.Black}
        />
      )}
    </div>
  );
}
