import { useContext, useEffect, useState } from 'react';
import { StatusPage } from './StatusPage';
import { NavigationContext } from 'src/components/context/NavigationProvider';

export function QuickFormsIndexPage() {
  const [isLoading, setIsLoading] = useState(true);
  const { navigateNextBlock } = useContext(NavigationContext);

  useEffect(() => {
    // If no blocks are started, navigate to the first block
    // if (quickFormsBlockOrder.every(block => block.status === BlockStatus.Default)) {
    //   navigateNextBlock();
    //   return;
    // }

    // Otherwise, show status page
    setIsLoading(false);
  }, [navigateNextBlock]);

  if (isLoading) {
    return null;
  }

  return <StatusPage />;
}
