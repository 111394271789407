import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import './Selector.css';
import React, { useMemo } from 'react';
import { Label } from '../text/Label';
import { Colors } from 'src/types/colors';
import { Thumbnail, ThumbnailProps } from './Thumbnail';
import { SelectorText } from './SelectorText';

interface MultiuseSelectorProps {
  to: string;
  thumbnail?: ThumbnailProps;
  image?: string;
  alt?: string;
  title?: string;
  subtitle?: string;
  label?: { label: string; labelColor: Colors }[];
  labelColor?: Colors;
  btnIcon?: any;
  onBtnClick?: () => void;
  children?: any;
  vehicleImage?: React.ReactNode;
  linkState?: any;
}

export default function MultiuseSelector({
  to,
  thumbnail,
  title,
  subtitle,
  label,
  labelColor,
  btnIcon,
  onBtnClick,
  children,
  linkState,
}: MultiuseSelectorProps) {
  const labels = useMemo(() => {
    let labels: { label: string; labelColor: Colors }[] = [];
    if (label && typeof label === 'string' && labelColor) {
      labels.push({ label, labelColor });
    }

    if (Array.isArray(label)) {
      labels = label;
    }
    return labels;
  }, [label, labelColor]);

  const renderClass = useMemo(() => {
    const classes = ['selector--default-link selector-global'];
    if (btnIcon) classes.push('is-btn-selector');
    return classes.join(' ');
  }, [btnIcon]);

  return (
    <>
      <Link to={to} className={renderClass} state={linkState}>
        <Thumbnail thumbnail={thumbnail} />
        <SelectorText title={title} subtitle={subtitle} />

        {labels.map(item => (
          <Label key={item.label} color={item.labelColor} text={item.label} />
        ))}
      </Link>

      {btnIcon && (
        <button type="button" onClick={onBtnClick} className="selector--btn icon-btn">
          <FontAwesomeIcon icon={btnIcon} className="" />
        </button>
      )}

      {children}
    </>
  );
}
