/* eslint-disable react-hooks/exhaustive-deps */
import { useForm, useWatch } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import FileUpload from '../form/FileUpload';

import { DocumentBlock, DocumentTag } from 'src/types/document-block';
import { FormBody, useFormSubmit } from '../form/Form';

const validationSchema = Joi.object({
  photos: Joi.array().items(Joi.string()),
});

export const TradeinPhotoGroup = () => {
  const { handleFormGroupSubmit, formValues: tradein, activeForm } = useFormSubmit();
  const form = useForm({
    mode: 'onBlur',
    resolver: joiResolver(validationSchema),
    defaultValues: {
      photos: tradein.photos,
    },
  });
  const photos = useWatch({ control: form.control, name: 'photos' });

  const updatePhotoList = (newPhotos: DocumentBlock[]) => {
    const newPhotosUrls = newPhotos.map(item => item.url);
    const updatedPhotos = [...photos, ...newPhotosUrls];
    form.setValue('photos', updatedPhotos);
  };

  const removePhotoInList = async (src: string, key: string) => {
    form.setValue(
      'photos',
      // cover newly uploaded and saved photos
      photos.filter(photo => photo !== src && photo !== key)
    );
  };

  if (activeForm !== 'tradeinPhotoForm') {
    return null;
  }

  return (
    <form
      id="tradeinPhotoForm"
      onSubmit={form.handleSubmit(handleFormGroupSubmit)}
      className="block-container--content--flex"
    >
      <FormBody
        title="Do you have photos of your vehicle?"
        body={`${tradein.year} ${tradein.make} ${tradein.model}`}
      />
      <FileUpload
        files={null}
        tag={DocumentTag.TradeIn}
        currentPhotos={photos.map(photo => ({ key: photo, url: photo }))}
        handleSubmit={updatePhotoList}
        removeFile={removePhotoInList}
      />
    </form>
  );
};
