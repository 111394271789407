import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import './Selector.css';
import './MenuSelector.css';
import { Lines } from '../../svg/lines';
import { BlockRoute, BlockStatus, FullBlockRoute } from '../../types/blocks';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { useDeals } from '../context/DealsProvider';
import { Tooltip } from '../floating/popover/Tooltip';
import { Popover, PopoverTrigger } from '../floating/popover/Popover';
import { useMobileContext } from '../context/MobileProvider';
import { NextAppointmentString } from 'src/pages/deal/appointments/NextAppointmentString';
import { Colors } from 'src/types/colors';
import { useMenuStatus } from '../context/status/useMenuStatus';

interface MenuSelectorProps {
  name: BlockRoute;
  to: FullBlockRoute;
  isDealDependent?: boolean;
  icon?: any;
  iconDone?: any;
  title: string;
  subtitle?: string;
  subtitleDone?: string;
  children?: any;
  tip?: string;
}

export default function MenuSelector({
  name,
  to,
  isDealDependent,
  icon,
  iconDone,
  title,
  subtitle,
  subtitleDone,
  children,
  tip,
}: MenuSelectorProps) {
  const isMobile = useMobileContext();
  const { deal, activeDealId } = useDeals();
  const { isActive, renderClass, status } = useMenuStatus(name, 'menu-selector--link');

  const handleBtnClick = (e: any) => {
    if (status === BlockStatus.Disabled) {
      e.preventDefault();
    }
  };

  if (status === BlockStatus.Hidden) {
    return null;
  }

  return (
    <>
      <Link
        to={isDealDependent ? `/deals/${activeDealId}${to}` : to}
        className={renderClass}
        onClick={handleBtnClick}
      >
        <div className="menu-selector--icon--holder">
          {status === BlockStatus.Disabled ||
            (status === BlockStatus.Incomplete && (
              <div className="menu-selector--icon--disabled--holder">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  className={`menu-selector--icon--disabled`}
                />
              </div>
            ))}
          <div className="menu-selector--icon--ring">
            <FontAwesomeIcon
              icon={status === BlockStatus.Done && iconDone ? iconDone : icon}
              className="menu-selector--icon"
            />
          </div>
        </div>
        <div className="selector--text">
          <div className="selector--title">{title}</div>
          {subtitle && status !== BlockStatus.Done && (
            <div className="selector--subtitle">{subtitle}</div>
          )}
          {status === BlockStatus.Done && (
            <div className="selector--subtitle">
              {subtitleDone || subtitle}
              {name === BlockRoute.Appointments ? <NextAppointmentString /> : null}
            </div>
          )}
        </div>
        {children}
        {status === BlockStatus.Disabled && !isMobile && tip && (
          <Popover initialOpen={!deal?.vin && name === BlockRoute.PaymentOptions} placement="right">
            <PopoverTrigger className="popover-tooltip--absolute-trigger"></PopoverTrigger>
            <Tooltip
              text={deal?.vin ? 'Please select another vehicle to finalize this offer' : tip}
              color={Colors.Blue1}
            />
          </Popover>
        )}
      </Link>
      <Lines isActive={status === BlockStatus.Done || isActive} />
    </>
  );
}
