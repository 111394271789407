// /* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { TermsGroup } from './TermsGroup';
import { Email, FirstName, LastName, MiddleName, Phone } from '../inputs/Input';
import {
  optionalCommunicationSchema,
  middleName,
  optionalEmailSchema,
} from '../../constants/schemas';
import { FormBody, useFormSubmit } from '../form/Form';
import { CreditApplicationActionBar } from '../form/CreditApplicationActionBar';

export interface PaymentInfoAndCommunicationGroupProps {
  requirePhone: boolean;
}

export const PaymentInfoAndCommunicationGroup = ({
  requirePhone,
}: PaymentInfoAndCommunicationGroupProps) => {
  const { activeForm, handleFormGroupSubmit, formValues: payment } = useFormSubmit();
  let schema;
  if (requirePhone) {
    schema = optionalEmailSchema;
  } else {
    schema = optionalCommunicationSchema;
  }
  const form = useForm({
    resolver: joiResolver(Joi.object({ ...schema, middleName })),
    mode: 'onBlur',
    defaultValues: {
      firstName: payment.firstName,
      lastName: payment.lastName,
      middleName: payment.middleName,
      email: payment.email,
      phone: payment.phone,
      optoutCalls: payment.optoutCalls,
      optoutSms: payment.optoutSms,
      optoutEmails: payment.optoutEmails,
    },
  });

  if (activeForm !== 'paymentInfoAndCommunicationForm') {
    return null;
  }

  return (
    <form id="paymentInfoAndCommunicationForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <FormBody
        title="Provide your basic information"
        body="The personal information you provide will be securely kept and will only be used to process your loan application."
      />
      <CreditApplicationActionBar />
      <div className="u-display-grid grid--50">
        <FirstName control={form.control} />
        <MiddleName control={form.control} />
      </div>
      <LastName control={form.control} />
      <Email control={form.control} />
      <Phone control={form.control} />
      <TermsGroup control={form.control} />
    </form>
  );
};
