import { useRef, useState } from 'react';
import { faSync, faUser, faExchangeAlt, faUserFriends } from '@fortawesome/pro-regular-svg-icons';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Selector from '../selectors/Selector';
import { FullBlockRoute } from '../../types/blocks';
import { CreditApplicationBlock, Signer } from 'src/types/creditapplication-block';
import ActionMenu from '../floating/popover/ActionMenu';
import { LoginState } from '../authentication/LoginForm';
import { useAuth } from '../context/AuthProvider';

interface CreditApplicationCardProps {
  application: CreditApplicationBlock;
  handleErrorMessage: (message: string) => void;
  handleSuccessMessage: (message: string) => void;
  creditApplicationsDelete: (id: string) => Promise<void>;
  creditApplicationsSwap: () => Promise<void>;
}

export default function CreditApplicationCard({
  application,
  handleErrorMessage,
  handleSuccessMessage,
  creditApplicationsDelete,
  creditApplicationsSwap,
}: CreditApplicationCardProps) {
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { token } = useAuth();

  const swapCreditApplications = async () => {
    setIsLoading(true);

    try {
      await creditApplicationsSwap();
      handleSuccessMessage('Application type updated');
    } catch (error: any) {
      handleErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleType = () => {
    swapCreditApplications();
  };

  const deleteCard = async () => {
    setIsLoading(true);

    try {
      await creditApplicationsDelete(application.id);
      handleSuccessMessage('Application Removed');
    } catch (error: any) {
      handleErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="u-mar-bottom-16">
      <div className={isLoading ? 'selector is-processing' : 'selector'} ref={containerRef}>
        <Selector
          to={token ? `${FullBlockRoute.CreditApplication}${application.id}` : '/login'}
          thumbnail={{ icon: application.type === Signer.Primary ? faUser : faUserFriends }}
          title={`${application.firstName} ${application.lastName}`}
          subtitle={`${
            application.type === Signer.Primary ? 'Primary' : 'Secondary'
          } credit application`}
          linkState={LoginState.Verify}
        >
          {token ? (
            <ActionMenu
              containerRef={containerRef}
              isSubmitting={isLoading}
              menuItems={[
                {
                  icon: faSync,
                  text: 'Edit',
                  onClick: () => {
                    navigate(`${FullBlockRoute.CreditApplication}${application.id}`);
                  },
                },
                {
                  icon: faExchangeAlt,
                  text: `Change to ${application.type === Signer.Primary ? 'Cosigner' : 'Primary'}`,
                  onClick: toggleType,
                },
                {
                  icon: faMinusCircle,
                  text: 'Remove',
                  onClick: deleteCard,
                  confirmModal: {
                    title: 'Are you sure?',
                    body: 'You will not be able to recover this application',
                  },
                },
              ]}
            />
          ) : null}
        </Selector>
      </div>
    </div>
  );
}
