import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfig } from 'src/components/context/config';
import { OnMessageFn, WidgetForm, WidgetHostMessageType } from 'src/widgets/SpaceWidget';

export type UnauthorizedSubmitCallback = (
  email: string | null,
  dealId: string,
  customerId: string,
  block?: any
) => Promise<void>;

export const useHandleSuccess = (
  hostMessage: OnMessageFn,
  form: WidgetForm,
  customNavigationHandle?: any
): UnauthorizedSubmitCallback => {
  const config = useConfig()!;
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    async (email: string | null, dealId: string, customerId: string, block?: any) => {
      toast.success(config.formConfirmation?.toast ?? 'Your form was submitted!');

      const queryParams: any = {
        email: email ? email : '',
        vin: block?.vin ?? undefined,
        dealId: dealId,
        appointment: block?.appointment ?? undefined,
      };

      // Convert Params to a Record<string, string>
      const queryParamsRecord: Record<string, string> = {};
      for (const key in queryParams) {
        if (queryParams[key] !== undefined) {
          queryParamsRecord[key] = queryParams[key] as string;
        }
      }

      hostMessage({
        type: WidgetHostMessageType.Submit,
        payload: {
          email: email,
          dealId: dealId,
          form: form,
          customerId,
          queryParams: new URLSearchParams(queryParamsRecord).toString(),
        },
      });
      // Navigation logic
      if (location.pathname.includes('form')) {
        navigate('/thank-you', { state: form });
      } else if (customNavigationHandle) {
        customNavigationHandle();
        return;
      } else {
        navigate(`/${form}/success`, { state: block });
      }
    },
    [
      hostMessage,
      form,
      navigate,
      location.pathname,
      customNavigationHandle,
      config.formConfirmation,
    ]
  );
};
