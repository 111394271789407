import { Control } from 'react-hook-form';
import { useConfig } from '../context/config';
import NegativeToggle from '../controls/NegativeToggle';
import { NegativeToggleError } from '../controls/NegativeToggleError';
import './HardPullGroup.css';

export function TermsGroup({ control }: { control: Control<any> }) {
  const config = useConfig()!;

  return (
    <div>
      <div className="terms--holder">
        <div className="terms--title">How may we follow up with you? </div>
      </div>

      <div className="terms--small-text">
        Message and data rates may apply. See our{' '}
        <a
          href={`https://${config.websiteDomain}/terms-of-service/`}
          target="_blank"
          className="u-blue-link"
          rel="noreferrer"
        >
          terms of service
        </a>{' '}
        or{' '}
        <a
          href={`https://${config.websiteDomain}/privacy-policy/`}
          target="_blank"
          className="u-blue-link"
          rel="noreferrer"
        >
          privacy policy
        </a>{' '}
        for more information.
      </div>
      <div className="u-display-flex">
        <NegativeToggle control={control} name="optoutCalls" label="Phone" />
        <NegativeToggle control={control} name="optoutSms" label="SMS" />
        <NegativeToggle control={control} name="optoutEmails" label="Email" />
      </div>
      {/* To do: pass in array of controls and look for error in any of them */}
      <NegativeToggleError control={control} name="optoutCalls" />
    </div>
  );
}
