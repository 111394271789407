import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Selector.css';
import { useMemo } from 'react';
import { Label } from '../text/Label';
import { Colors } from 'src/types/colors';
import { Thumbnail, ThumbnailProps } from './Thumbnail';
import { SelectorText } from './SelectorText';

interface MultiuseSelectorProps {
  thumbnail?: ThumbnailProps;
  title?: string;
  subtitle?: string;
  label?: { label: string; labelColor: Colors }[];
  labelColor?: Colors;
  btnIcon?: any;
  onBtnClick?: () => void;
  handleRowClick?: (e?) => void;
  children?: any;
  isActive?: boolean;
  isWideGraphic?: boolean;
  isEmptyState?: boolean;
}

export default function SelectorRow({
  thumbnail,
  title,
  subtitle,
  label,
  labelColor,
  btnIcon,
  onBtnClick,
  children,
  handleRowClick,
  isActive,
}: MultiuseSelectorProps) {
  const labels = useMemo(() => {
    let labels: { label: string; labelColor: Colors }[] = [];
    if (label && typeof label === 'string' && labelColor) {
      labels.push({ label, labelColor });
    }

    if (Array.isArray(label)) {
      labels = label;
    }
    return labels;
  }, [label, labelColor]);

  const onRowClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (handleRowClick) {
      handleRowClick();
    }
  };
  const renderClass = useMemo(() => {
    const classes = ['selector--row-link'];
    if (isActive) classes.push('is-active');
    if (!btnIcon) classes.push('mod-no-btn');
    return classes.join(' ');
  }, [isActive, btnIcon]);

  return (
    <>
      <button type="button" className={renderClass} onClick={onRowClick}>
        <Thumbnail thumbnail={thumbnail} />
        <SelectorText title={title} subtitle={subtitle} />

        {labels.map(item => (
          <Label key={item.label} color={item.labelColor} text={item.label} />
        ))}
      </button>

      {btnIcon && (
        <button type="button" onClick={onBtnClick} className="selector--btn icon-btn">
          <FontAwesomeIcon icon={btnIcon} className="" />
        </button>
      )}

      {children}
    </>
  );
}
