// MenuContext.tsx
import React, { createContext, useContext, useState, useCallback } from 'react';

interface AccordionContextProps {
  isMenuOpen: boolean;
  toggleMenu: () => void;
}

const AccordionContext = createContext<AccordionContextProps | undefined>(undefined);

export const useAccordion = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error('useMenu must be used within a MenuProvider');
  }
  return context;
};

export const AccordionProvider: React.FC<{ children: React.ReactNode }> = ({ children }: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => setIsMenuOpen(!isMenuOpen), [isMenuOpen]);

  return (
    <AccordionContext.Provider value={{ isMenuOpen, toggleMenu }}>
      {children}
    </AccordionContext.Provider>
  );
};
