import { useContext } from 'react';
import toast from 'react-hot-toast';
import { FormActions } from '../../components/form/FormActions';
import { BlockRoute } from 'src/types/blocks';
import { formatCustomerAddonBlock } from 'src/types/lead';
import { Customer } from 'src/types/customer';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import { FormBody, FormContentWrapper, FormWrapper, useSpaceForm } from 'src/components/form/Form';
import { communicationSchema } from 'src/constants/schemas';
import { Customer as CustomerGroup } from 'src/components/steps/CustomerGroup';
import { CustomerBlock } from 'src/types/customer-block';
import { FormJourneyTab } from 'src/components/navigation/JourneyTab';

interface MissingInfoFormProps {
  handleNext: () => void;
  me: Customer;
  meUpsert: any;
}

export function MissingInfoForm({ me, meUpsert, handleNext }: MissingInfoFormProps) {
  const activeForm = 'missingInfoForm';
  const { navigatePrevBlock } = useContext(NavigationContext);

  const handleFormGroupSubmit = async (data: CustomerBlock) => {
    const customer = formatCustomerAddonBlock(data);

    try {
      await meUpsert(customer);
      toast.success('Information saved');
      handleNext();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const form = useSpaceForm({ communicationSchema }, me);

  return (
    <FormWrapper
      handleFormSubmit={handleFormGroupSubmit}
      handleFormCancel={() => navigatePrevBlock(BlockRoute.Review)}
      multistepFormValues={me}
      steps={[activeForm]}
    >
      <FormJourneyTab />
      <FormContentWrapper form={form} id={activeForm}>
        <FormBody
          title="Missing Information"
          body="Before you review your terms we need some more information about you!"
        />
        <CustomerGroup form={form}></CustomerGroup>
      </FormContentWrapper>
      <FormActions />
    </FormWrapper>
  );
}
