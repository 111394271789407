import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Selector.css';
import { Label } from '../text/Label';
import { Colors } from 'src/types/colors';
import { Thumbnail, ThumbnailProps } from './Thumbnail';
import { SelectorText } from './SelectorText';

interface ReadOnlySelectorProps {
  thumbnail?: ThumbnailProps;
  alt?: string;
  title?: string;
  subtitle?: string;
  label?: string;
  labelColor?: Colors;
  btnIcon?: any;
}

export function ReadOnlySelector({
  thumbnail,
  title,
  subtitle,
  label,
  labelColor,
  btnIcon,
}: ReadOnlySelectorProps) {
  return (
    <div className="read-only-selector">
      <Thumbnail thumbnail={thumbnail} />
      <SelectorText title={title} subtitle={subtitle} />

      {label && <Label color={labelColor || Colors.Blue2} text={label} />}

      {btnIcon && (
        <div className="selector--btn icon-btn">
          <FontAwesomeIcon icon={btnIcon} className="" />
        </div>
      )}
    </div>
  );
}
