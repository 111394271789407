import { RefObject } from 'react';
import Button from '../../form/Button';
import { DialogContent, DialogHeading, DialogDescription, DialogClose } from './Dialog';
import { useConfig } from 'src/components/context/config';

interface ConfirmModalProps {
  headerText: string;
  bodyText: string | JSX.Element;
  confirmAction: () => void;
  isSubmitting: boolean;
  containerRef?: RefObject<HTMLDivElement> | null;
}

export default function ConfirmModal({
  headerText,
  bodyText,
  confirmAction,
  containerRef,
  isSubmitting,
}: ConfirmModalProps) {
  const config = useConfig()!;
  return (
    <DialogContent className="dialog" containerRef={config.isRetailing ? null : containerRef}>
      <DialogHeading className="dialog-heading">{headerText}</DialogHeading>
      <DialogDescription className="dialog-description">{bodyText}</DialogDescription>
      <div className="dialog-footer">
        {confirmAction ? (
          <Button
            type="button"
            text="Remove"
            baseClass="btn mod-danger"
            isSubmitting={isSubmitting}
            progress={true}
            handleSubmit={confirmAction}
          />
        ) : null}
        <DialogClose className="btn mod-tertiary">Dismiss</DialogClose>
      </div>
    </DialogContent>
  );
}
