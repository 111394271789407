import { useRef } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { faCalendarDay } from '@fortawesome/pro-regular-svg-icons';
import { PatternFormat } from 'react-number-format';
import './DateDialog.css';
import { useConfig } from '../context/config';
import { Popover, PopoverTrigger, PopoverContent } from '../floating/popover/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DateDialogProps {
  updateDatepickerInput: (value: string) => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  initialDate: Date;
  handleDayClick: any;
  disabledDays: { dayOfWeek: number[] };
  inputAppointmentDate: string;
  calendarAppointmentDate: Date;
  handleBlur: (name: string) => void;
}

export function DateDialog({
  updateDatepickerInput,
  open,
  setOpen,
  initialDate,
  handleDayClick,
  disabledDays,
  inputAppointmentDate,
  calendarAppointmentDate,
  handleBlur,
}: DateDialogProps) {
  const containerRef = useRef(null);
  const config = useConfig()!;

  return (
    <div ref={containerRef}>
      <Popover open={open} onOpenChange={setOpen}>
        <div className="form--datepicker-holder">
          <PopoverTrigger className="popover--icon-trigger" onClick={() => setOpen(true)}>
            <FontAwesomeIcon icon={faCalendarDay} />
          </PopoverTrigger>

          <PatternFormat
            format="##/##/####"
            value={inputAppointmentDate}
            placeholder="mm/dd/yyyy"
            mask={['_', '_', '_', '_', '_', '_', '_', '_']}
            className="form--input--text"
            onBlur={() => {
              handleBlur('inputAppointmentDate');
            }}
            onChange={event => {
              updateDatepickerInput(event.currentTarget.value);
            }}
          />
        </div>

        <PopoverContent
          className={`popover-calendar ${config.isRetailing ? ' is-retailing' : ' is-widget'}`}
          containerRef={config.isRetailing ? null : containerRef}
        >
          <div id="datepicker" role="dialog">
            <div id="day" className="datepicker--modal-holder">
              <DayPicker
                initialFocus={open}
                mode="single"
                defaultMonth={initialDate}
                fromDate={new Date()}
                selected={calendarAppointmentDate}
                onSelect={handleDayClick}
                disabled={disabledDays}
              />
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
