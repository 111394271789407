import './VehicleSelector.css';
import { useRef, useMemo } from 'react';
import { faAngleDown, faCar, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { CarouselPopover } from '../floating/popover/Carousel';
import { VehicleStatus, returnVehiclePhoto } from '../../types/vehicle';
import { SingleDealResponse } from 'src/types/deal';
import { useConfig } from '../context/config';
import { VehicleCardImage } from '../cards/vehicle/VehicleCardImage';
import { VehicleCardTitle } from '../cards/vehicle/VehicleCardTitle';
import { VehicleCardBody } from '../cards/vehicle/VehicleCardBody';
import { Dialog } from '../floating/dialog/Dialog';
import { useMenu } from '../context/MenuProvider';
import { VehicleCardPrice } from '../cards/vehicle/VehicleCardPrice';
import { useMonthlyPayment } from 'src/helpers/useMonthlyPayment';
import MultiuseSelector from './Selector';
import { Colors } from 'src/types/colors';
import { useDeals } from '../context/DealsProvider';
import Button from '../form/Button';
import { useVehicleSelectionData } from '../../helpers/useVehicleSelectionData';
import { useVehicleVdp } from 'src/fetches/useSWRFetch';

interface VehicleSelectorProps {
  vehicle?: SingleDealResponse;
}

export function toggleMenu(setIsOpen: (isOpen: boolean) => void, isOpen: boolean) {
  setIsOpen(!isOpen);
}

export default function VehicleSelectorMobile({ vehicle }: VehicleSelectorProps) {
  const config = useConfig()!;
  const { activeDealId } = useDeals();
  const {
    monthlyPayment,
    monthlyPaymentTermType,
    monthlyPaymentIsHidden,
    monthlyPaymentIsLoading,
  } = useMonthlyPayment();
  const { isVehicleSelectorOpen, toggleVehicleSelector } = useMenu();
  const { vehicleSelectionDataIsLoading, vehicleSelectionDataExists } = useVehicleSelectionData();
  const { vehicleVdp } = useVehicleVdp(vehicle?.vin);
  const containerRef = useRef<HTMLDivElement>(null);

  const openVehicleVdp = async () => {
    // Send user to main website to pick a vehicle
    window.open(vehicleVdp?.url);
  };

  const renderClass = useMemo(() => {
    const classes = ['vehicle-selector--mobile-link'];

    if (isVehicleSelectorOpen) classes.push('is-overlay-open');
    return classes.join(' ');
  }, [isVehicleSelectorOpen]);

  if (vehicleSelectionDataIsLoading) return null;

  return (
    <div className={renderClass} ref={containerRef}>
      {vehicle?.vin ? (
        <button className="vehicle-selector--holder" onClick={openVehicleVdp}>
          <div className="vehicle-selector--image--holder">
            <VehicleCardImage
              imageSrc={returnVehiclePhoto(vehicle)}
              key={vehicle.photo}
              className="modular-vehicle-card-img"
            />
          </div>
          <div className="modular-vehicle-card--bottom">
            <div className="u-text-ellipsis">
              <VehicleCardTitle
                vehicle={vehicle}
                isAvailable={vehicle.vehicleStatus === VehicleStatus.Available}
              />
              <div className="modular-vehicle-card--subtitle text--body-1">
                <VehicleCardBody trim={vehicle.trim} />
              </div>
              <div className="modular-vehicle-card--subtitle">
                <div className="modular-vehicle-card--subtitle--flex">
                  <div className="text--title-1">
                    <VehicleCardPrice
                      vin={vehicle.vin}
                      price={vehicle.sellingPrice}
                      isAvailable={vehicle.vehicleStatus === VehicleStatus.Available}
                      mileage={vehicle.mileage}
                    />
                  </div>
                  {!monthlyPaymentIsHidden && !monthlyPaymentIsLoading && (
                    <>
                      <div className="text--body-1">•</div>
                      <div className="text--body-1 u-color-accent">
                        {monthlyPayment}
                        {''}
                        {monthlyPaymentTermType}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </button>
      ) : (
        <div className="selector--menu-item">
          <div className="selector is-orange-alert">
            <MultiuseSelector
              to={`https://${config.websiteDomain}/cars?dealId=${
                activeDealId === 'new' ? 'create' : activeDealId
              }`}
              thumbnail={{ icon: faCar, color: Colors.Black }}
              btnIcon={faPlus}
              title="Choose a Vehicle"
              subtitle="Search Our Inventory"
              onBtnClick={() =>
                window.open(
                  `https://${config.websiteDomain}/cars?dealId=${
                    activeDealId === 'new' ? 'create' : activeDealId
                  }`
                )
              }
            />
          </div>
        </div>
      )}

      {vehicleSelectionDataExists ? (
        <Button
          type="button"
          text=""
          baseClass="icon-btn mod-outline"
          icon={true}
          iconName={faAngleDown}
          isSubmitting={false}
          progress={true}
          handleSubmit={toggleVehicleSelector}
        />
      ) : null}

      <Dialog open={isVehicleSelectorOpen} onOpenChange={toggleVehicleSelector}>
        <CarouselPopover ref={containerRef} />
      </Dialog>
    </div>
  );
}
