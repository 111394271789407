import {
  faAngleDown,
  faEnvelope,
  faPhone,
  faQuestionCircle,
  faSignOut,
  faUser,
  faUserCircle,
  faUserHeadset,
} from '@fortawesome/pro-regular-svg-icons';
import './RightMenu.css';
import { Popover, PopoverTrigger, PopoverContent, ContextItem } from './floating/popover/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfig } from './context/config';
import { Link } from 'react-router-dom';
import { FullBlockRoute } from '../types/blocks';
import { useMe, useSwrDeals } from 'src/fetches/useSWRFetch';
import { useMemo } from 'react';
import { returnUserFriendlyPhone } from 'src/helpers/formatPhone';
import { Colors } from 'src/types/colors';
import { WebsiteRoute } from 'src/external/WebsiteRoutes';

interface RightMenuProps {
  handleLogout: () => void;
}
export default function RightMenu({ handleLogout }: RightMenuProps) {
  const config = useConfig()!;
  const hasCrm = config.packages?.crm === true;
  const { me, meIsLoading } = useMe();
  const { dealsIsLoading } = useSwrDeals();

  const phone: string = useMemo(() => {
    // Use dealerPhone, dealershipPhone, or the first location's phone
    const phoneNumberToUse = hasCrm
      ? me?.dealerPhone || me?.dealershipPhone
      : config.locations[0]?.phone;
    return phoneNumberToUse || '';
  }, [hasCrm, me, config]);

  const email: string = useMemo(() => {
    // Use dealerEmail, dealershipEmail, or the first location's email
    const emailToUse = hasCrm ? me?.dealerEmail || me?.dealershipEmail : config.locations[0]?.email;
    return emailToUse || '';
  }, [hasCrm, me, config]);

  const profileBtnText: string = useMemo(() => {
    // Return First and last name, else email, else 'Update Profile'
    if (me?.firstName && me?.lastName) {
      return `${me?.firstName} ${me?.lastName}`;
    } else if (me?.email) {
      return me?.email;
    }

    return 'Update Profile';
  }, [me]);

  const formattedPhone: string = useMemo(() => phone.substring(2), [phone]);

  const userFriendlyPhone = useMemo(() => returnUserFriendlyPhone(phone), [phone]);

  if (meIsLoading || !me) {
    return null;
  }

  if (dealsIsLoading) return null;

  return (
    <div className="right-menu">
      {/* For Deal Testing, import and use this component: */}
      {/* <TestDealActions /> */}

      <Popover placement="bottom-end">
        <PopoverTrigger className="popover-btn">
          <FontAwesomeIcon icon={faPhone} transform={{ flipX: true }} />
          <span className="popover-btn--text">Need Help?</span>
          <FontAwesomeIcon icon={faAngleDown} color={Colors.Gray2} />
        </PopoverTrigger>
        <PopoverContent className="popover-context-menu">
          <a href={`tel:${formattedPhone}`}>
            <ContextItem icon={faPhone} text={`Call ${userFriendlyPhone}`} />
          </a>
          <a href={`mailto:${email}`}>
            <ContextItem icon={faEnvelope} text={email} />
          </a>
          <a
            href={`https://${config.websiteDomain}${WebsiteRoute.Contact}`}
            target="_blank"
            rel="noreferrer"
          >
            <ContextItem icon={faUserHeadset} text="Contact Us" />
          </a>
          <a
            href={`https://${config.websiteDomain}${WebsiteRoute.Faq}`}
            target="_blank"
            rel="noreferrer"
          >
            <ContextItem icon={faQuestionCircle} text="FAQ" />
          </a>
        </PopoverContent>
      </Popover>

      <Popover placement="bottom-end">
        <PopoverTrigger className="popover-btn">
          <FontAwesomeIcon icon={faUser} />
          <span className="popover-btn--text">{profileBtnText}</span>
          <FontAwesomeIcon icon={faAngleDown} color={Colors.Gray2} />
        </PopoverTrigger>
        <PopoverContent className="popover-context-menu">
          <Link to={FullBlockRoute.Account}>
            <ContextItem icon={faUserCircle} text="My Account" />
          </Link>
          <button onClick={handleLogout} type="button">
            <ContextItem icon={faSignOut} text="Sign Out" color={Colors.Red1} />
          </button>
        </PopoverContent>
      </Popover>
    </div>
  );
}
