import { useParams } from 'react-router-dom';
import { BlockRoute } from 'src/types/blocks';
import AppointmentsIndexPage from '../appointments';
import PaymentOptionsIndexPage from '../payment-options';
import ReviewIndexPage from '../review';
import CreditApplicationIndexPage from '../payment-method';
import TradeInsIndexPage from '../trade-in';
import DocumentsIndexPage from '../documents';
import BasicInfoIndexPage from 'src/pages/basic-info';
import { VehiclesIndexPage } from 'src/pages/vehicles';
import ContactIndexPage from 'src/pages/contact';
import { QuickFormsIndexPage } from 'src/pages/quick-forms';
import Chat from 'src/components/chat/Chat';
import ReferencesIndexPage from '../references';

export function IndexPage() {
  const { block } = useParams();

  // switch through possible block routes to return right markup

  switch (block) {
    case BlockRoute.Account:
    case 'basic-info': //deprecated
    case 'my-profile': //deprecated
    case 'profile': //deprecated
      return <BasicInfoIndexPage />;
    case 'vehicles':
    case 'myvehicles':
    case 'my-vehicles':
    case 'reservations':
    case 'favorites':
      return <VehiclesIndexPage />;
    case 'expert':
    case 'myexpert':
    case 'my-expert':
    case 'contact':
      return <ContactIndexPage />;

    case 'quickforms':
    case 'quick-forms':
    case 'forms':
    case 'express-forms':
      return <QuickFormsIndexPage />;

    case BlockRoute.Chat:
      return <Chat />;

    // Actual blocks
    case BlockRoute.PaymentOptions:
      return <PaymentOptionsIndexPage />;
    case BlockRoute.TradeIn:
    case 'tradeins':
      return <TradeInsIndexPage />;
    case BlockRoute.CreditApplication:
    case 'payment-method': //deprecated
      return <CreditApplicationIndexPage />;
    case BlockRoute.Appointments:
      return <AppointmentsIndexPage />;
    case BlockRoute.Documents:
      return <DocumentsIndexPage />;
    case BlockRoute.References:
      return <ReferencesIndexPage />;
    case BlockRoute.Review:
      return <ReviewIndexPage />;
    default:
      return <PaymentOptionsIndexPage />;
  }
}
