import { UseFormReturn } from 'react-hook-form';
import { AppointmentPicker } from '../form/AppointmentPicker';
import { ScheduleProvider } from '../context/ScheduleProvider';

export function AppointmentTime({ form }: { form: UseFormReturn<any> }) {
  const formLocation = form.watch('location');
  return (
    <ScheduleProvider location={formLocation}>
      <AppointmentPicker form={form} initialDate={form.getValues('calendarAppointmentDate')} />
    </ScheduleProvider>
  );
}
