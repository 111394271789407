import { useParams, useSearchParams } from 'react-router-dom';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useReference, useReferences } from '../../../fetches/useSWRFetch';
import PageLoader from '../../../components/PageLoader';
import { ReferenceForm } from '../references/ReferenceForm';
import DocumentSuccessPage from './ReferenceSuccessPage';
import { BlockRoute, FullBlockRoute } from '../../../types/blocks';
import { ReferenceSubmitBlock } from 'src/types/reference-block';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import { useAuth } from 'src/components/context/AuthProvider';
import PageError from 'src/components/PageError';
import { UnauthorizedLeadForm } from 'src/lead-forms/UnauthorizedLeadForm';
import { WidgetForm } from 'src/widgets/SpaceWidget';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { State } from 'src/types/address';
import { useConfig } from 'src/components/context/config';
import Selector from 'src/components/selectors/Selector';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { LoginState } from 'src/components/authentication/LoginForm';
import { FormBasicPage } from 'src/components/form/FormShell';
import { FormBody } from 'src/components/form/Form';
import { useReferencesCount } from 'src/components/context/status/referenceCount';

function ReferenceBuilder({ id, index }: { id: string; index: number }) {
  const [isSuccessState, setIsSuccessState] = useState(false);
  const { reference, referenceIsError, referenceIsLoading, referenceSubmit } = useReference(
    id || 'new'
  );
  const { referencesSubmit } = useReferences();
  const { token } = useAuth();
  const { navigatePrevBlock } = useContext(NavigationContext);

  const handleBack = () => {
    navigatePrevBlock(BlockRoute.References);
  };

  const handleFormSubmit = async (data: any) => {
    const block: ReferenceSubmitBlock = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone === '' ? data.phone : `+1${data.phone.replace(/[^0-9]/g, '')}`,
      email: data.email,
      relationship: data.relationship || null,
      addressLine1: data.addresses[0].addressLine1 || null,
      addressLine2: data.addresses[0].addressLine2 || null,
      city: data.addresses[0].city || null,
      state: (data.addresses[0].state as State) || null,
      zip: data.addresses[0].zip || null,
    };

    try {
      if (token) {
        await referenceSubmit(block);
      } else {
        await referencesSubmit([block]);
      }
      setIsSuccessState(true);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  if (!reference || referenceIsLoading) return <PageLoader />;
  if (referenceIsError) return <PageError primaryText="That deal might not exist anymore" />;

  if (isSuccessState) return <DocumentSuccessPage />;

  return (
    <ReferenceForm
      index={index}
      reference={reference}
      handleFormCancel={handleBack}
      handleFormSubmit={handleFormSubmit}
    />
  );
}

export default function Reference() {
  const config = useConfig()!;
  const { sessionCustomer } = useSessionContext();
  const { references, referencesIsLoading } = useReferences();
  const { block, blockId } = useParams();
  const [searchParams] = useSearchParams();
  const { token } = useAuth();
  const index = searchParams.get('index') || '0';
  const referenceCount = useReferencesCount();

  if (!references || referencesIsLoading) return <PageLoader />;

  // All unauthorized users need full customer info to proceed
  if (!token && !sessionCustomer) {
    return (
      <UnauthorizedLeadForm
        form={WidgetForm.General}
        vehicle={null}
        title={'Add your references'}
        body={'Let us know a few quick things so we can securely store your references'}
        additionalFields={[]}
        vin={null}
        requireZip={false}
        customNavigationHandle={() => {}} // Stop navigation
        submitText="Continue"
      />
    );
  }

  if (!config.isRetailing && blockId === 'edit' && references[index]) {
    let reference = references[index];

    return (
      <FormBasicPage
        configuration={{
          alternateButtonText: 'Next',
          showSkipButton: true,
          blockRoute: block as BlockRoute,
        }}
      >
        <FormBody
          title={`Reference ${parseInt(index) + 1}`}
          body={`This institution requires ${referenceCount} references.`}
        />
        <Selector
          to={token ? `${FullBlockRoute.References}${reference.id}` : '/login'}
          thumbnail={{ icon: faUser }}
          title={`${reference.firstName} ${reference.lastName}`}
          subtitle="Reference"
          linkState={LoginState.Verify}
        />
      </FormBasicPage>
    );
  }

  return (
    <ReferenceBuilder
      key={index}
      index={parseInt(index)}
      id={blockId === 'edit' ? references[index].id : 'new'}
    />
  );
}
