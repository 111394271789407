import { useState, useMemo, useRef } from 'react';
import { faCalendarStar, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import Selector from '../selectors/Selector';
import { useAppointmentStatus } from '../../helpers/useAppointmentStatus';
import './AppointmentCard.css';
import { AppointmentStatus, AppointmentType } from '../../types/appointment';
import { AppointmentBlock } from 'src/types/appointment-block';
import { Colors } from 'src/types/colors';
import { returnVehiclePhoto } from 'src/types/vehicle';
import { VehicleCardImage } from './vehicle/VehicleCardImage';
import { useAuth } from '../context/AuthProvider';
import { LoginState } from '../authentication/LoginForm';
import { useDeals } from '../context/DealsProvider';
import { useConfig } from '../context/config';
import { returnLabelText } from 'src/constants/appointment';
import { getReadableTime, getShortReadableDate } from 'src/constants/dates';
import { useSchedule } from '../context/ScheduleProvider';
import { utcToZonedTime } from 'date-fns-tz';

interface AppointmentCardProps {
  appointment: AppointmentBlock;
  isTask?: boolean;
}

export default function AppointmentCard({ appointment, isTask = false }: AppointmentCardProps) {
  const config = useConfig()!;
  const { activeDealId } = useDeals();
  const [isLoading] = useState(false);
  const { token } = useAuth();
  const { labelColor } = useAppointmentStatus(appointment?.status);
  const { dealerTimezone } = useSchedule();
  const zonedAptDate = utcToZonedTime(appointment.appointmentAt, dealerTimezone);
  // Check if an appointment was made that is somehow out of hours
  const containerRef = useRef<HTMLDivElement>(null);

  const subtitle = useMemo(() => {
    const date = getShortReadableDate(zonedAptDate);
    const time = getReadableTime(zonedAptDate);

    if (appointment?.location && config.locations.length > 1) {
      return `${date} at ${time} • ${appointment.location}`;
    }
    return `${date} at ${time}`;
  }, [config.locations, appointment, zonedAptDate]);

  const labels = useMemo(() => {
    let labelsArray = [{ label: returnLabelText(appointment.status), labelColor }];

    return labelsArray;
  }, [labelColor, appointment]);

  const getTitle = () => {
    if (appointment?.type === AppointmentType.Sales) return 'Consultation';
    return appointment?.type || getShortReadableDate(zonedAptDate);
  };

  const renderClass = useMemo(() => {
    const classes = ['selector'];
    if (isLoading) classes.push('processing');
    if (
      appointment?.status === AppointmentStatus.Completed ||
      appointment?.status === AppointmentStatus.Missed
    )
      classes.push('is-disabled');
    return classes.join(' ');
  }, [appointment?.status, isLoading]);

  return (
    <div className="appointment-card--list u-mar-bottom-16">
      <div className={renderClass} ref={containerRef}>
        <Selector
          to={token ? `/deals/${activeDealId}/appointments/${appointment.id}` : '/login'}
          title={getTitle()}
          subtitle={subtitle}
          label={labels}
          labelColor={labelColor}
          thumbnail={
            isTask
              ? {
                  icon: faCheckCircle,
                  color: Colors.Green1,
                }
              : {
                  icon: faCalendarStar,
                  iconBackground: Colors.Gray7,
                  color: Colors.Gray0,
                  isWide: true,
                  element: appointment?.vehicle?.vin ? (
                    <VehicleCardImage
                      imageSrc={returnVehiclePhoto(appointment.vehicle)}
                      className="selector--img"
                    />
                  ) : null,
                }
          }
          linkState={LoginState.Verify}
        ></Selector>
      </div>
    </div>
  );
}
