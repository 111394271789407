import { faAngleDown, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { useMemo } from 'react';
import { useAccordion } from '../context/AccordionProvider';
import SelectorRow from './SelectorRow';
import { Colors } from 'src/types/colors';
import { Subtask } from '../navigation/Subtask';

export function NestedAccordion({
  items,
  title,
  subtitle = undefined,
  label,
}: {
  items: { title: string; label?: string }[];
  title: string;
  subtitle?: string;
  label?: string;
}) {
  const { isMenuOpen: isOpen, toggleMenu: toggleOpen } = useAccordion();

  const openAccordionHeight = useMemo(() => {
    let height = 64;

    if (isOpen) {
      height += items.length * 32; //Subtask item height
      height += 8; // Bottom padding
    }

    return height;
  }, [items.length, isOpen]);

  return items.length ? (
    <div
      className={`selector--accordion mod-accordion-list ${isOpen ? 'is-active' : ''}`}
      style={{ height: `${openAccordionHeight}px` }}
    >
      <div>
        <div className="selector">
          <SelectorRow
            title={title}
            subtitle={subtitle}
            handleRowClick={toggleOpen}
            onBtnClick={toggleOpen}
            label={label ? [{ label, labelColor: Colors.Gray6 }] : undefined}
            labelColor={Colors.Gray6 as Colors}
            btnIcon={isOpen ? faAngleDown : faAngleRight}
          />
        </div>
        {isOpen && (
          <div className="selector--accordion--menu">
            {items.map((item, index) => (
              <Subtask
                key={index}
                title={item.title}
                label={item.label}
                labelColor={Colors.Gray6}
                extender={index !== items.length - 1}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div
      className="selector--accordion mod-accordion-list"
      style={{ height: `${openAccordionHeight}px` }}
    >
      <div>
        <div className="selector">
          <SelectorRow
            title={title}
            subtitle={subtitle}
            label={label ? [{ label, labelColor: Colors.Gray6 }] : undefined}
            labelColor={Colors.Gray6 as Colors}
          />
        </div>
      </div>
    </div>
  );
}
