import { useEffect, useMemo, useState } from 'react';
import { useAccordion } from '../context/AccordionProvider';
import { returnVehiclePhoto, returnVehicleYearMakeModel } from 'src/types/vehicle';
import { faCalendarStar } from '@fortawesome/pro-regular-svg-icons';
import { SelectorAccordion } from './SelectorAccordion';
import SelectorRow from './SelectorRow';
import { useVehicleSelectionData } from '../../helpers/useVehicleSelectionData';
import { VehicleCardImage } from '../cards/vehicle/VehicleCardImage';
import { Colors } from 'src/types/colors';
export function VehicleSelectorAccordion({ form, initialVehicle }) {
  const [selectedVehicle, setSelectedVehicle] = useState(initialVehicle);
  const { isMenuOpen, toggleMenu } = useAccordion();
  const { vehicleSelectionDataMinusActiveVehicle, vehicleSelectionDataIsLoading } =
    useVehicleSelectionData(selectedVehicle);

  useEffect(() => {
    if (selectedVehicle) {
      form.setValue('vin', selectedVehicle.vin);
      // If the vehicle has a vin, set the location
      if (selectedVehicle.dealershipName) {
        form.setValue('location', selectedVehicle.dealershipName);
      }
      // Location exists under different keys
      if (selectedVehicle.location) {
        form.setValue('location', selectedVehicle.location);
      }
    }
  }, [selectedVehicle, form]);

  const selectableDeals = useMemo(() => {
    // If no deals or user is creating new deal
    if (vehicleSelectionDataIsLoading) return [];

    if (selectedVehicle?.vin) {
      // Add a no vehicle option
      vehicleSelectionDataMinusActiveVehicle.unshift({
        vin: null,
      });
    }
    return vehicleSelectionDataMinusActiveVehicle;
  }, [vehicleSelectionDataIsLoading, selectedVehicle, vehicleSelectionDataMinusActiveVehicle]);

  const renderVehicleRow = (vehicle, onClick, isActive) => {
    // If no vehicle, show general appointment
    if (!vehicle?.vin) {
      return (
        <SelectorRow
          thumbnail={{
            icon: faCalendarStar,
            color: Colors.Black,
            iconBackground: Colors.Gray7,
            isWide: true,
          }}
          title="No Vehicle"
          subtitle="General Appointment"
          handleRowClick={onClick}
          isActive={isActive}
          isWideGraphic={true}
          isEmptyState={true}
        />
      );
    } else {
      return (
        <SelectorRow
          thumbnail={{
            element: (
              <VehicleCardImage imageSrc={returnVehiclePhoto(vehicle)} className="selector--img" />
            ),
            isWide: true,
          }}
          title={returnVehicleYearMakeModel(vehicle)}
          subtitle={vehicle.trim}
          handleRowClick={onClick}
          isActive={isActive}
          isWideGraphic={true}
        />
      );
    }
  };

  const vehicleKeyExtractor = vehicle => vehicle.id;

  return (
    <SelectorAccordion
      items={selectableDeals}
      selectedItem={selectedVehicle}
      setSelectedItem={setSelectedVehicle}
      renderRow={renderVehicleRow}
      itemKey={vehicleKeyExtractor}
      isOpen={isMenuOpen}
      toggleOpen={toggleMenu}
    />
  );
}
