import { Link } from 'react-router-dom';
import PageLoader from '../../../components/PageLoader';
import PageError from '../../../components/PageError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useDocTitle } from '../../../helpers/global-document-title';
import { useCreditApplications } from '../../../fetches/useSWRFetch';
import Success from '../../../components/form/Success';
import { CreditApplicationRoute } from '../../../constants/routes';
import { useMemo } from 'react';
import { Signer } from 'src/types/creditapplication-block';

export default function CreditApplicationSuccessPage() {
  useDocTitle('Success!');
  const { creditApplications, creditApplicationsIsLoading, creditApplicationsIsError } =
    useCreditApplications();

  const hasPrimaryApplication: boolean = useMemo(
    () => creditApplications?.some(item => item.type === Signer.Primary),
    [creditApplications]
  );

  if (creditApplicationsIsLoading) return <PageLoader />;
  if (creditApplicationsIsError) return <PageError />;

  return (
    <Success
      title="Wooo! We made it through the tough one!"
      subtitle="Good job. It’s all downhill from here."
    >
      {creditApplications.length === 1 ? (
        <Link
          to={`${CreditApplicationRoute.route}new?type=${
            hasPrimaryApplication ? Signer.CoSigner : Signer.Primary
          }`}
          className="btn mod-primary mod-text-icon"
          reloadDocument={true}
        >
          <FontAwesomeIcon icon={faPlus} className="u-icon" />
          Add another
        </Link>
      ) : null}
    </Success>
  );
}
