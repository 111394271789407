import { PaymentMethodInputs } from 'src/constants/paymentType';
import { useConfig } from '../context/config';
import { PaymentType } from 'src/types/deal';
import { useDeals } from '../context/DealsProvider';
import { Radio } from '../controls/Radio';
import { useFormSubmit } from '../form/Form';

export function PaymentMethod({ form }) {
  const { isSubmitting } = useFormSubmit();
  const config = useConfig()!;
  const { deal } = useDeals();

  return (
    <div className="radio--flex-scroll">
      {PaymentMethodInputs.filter(
        item =>
          !config.paymentOptionsConfig[item.isDisabledKey] &&
          !(item.value === PaymentType.Leasing && !deal?.isLeaseEnabled)
      ).map((item, index) => (
        <Radio
          className="radio--flex-scroll--item"
          control={form.control}
          key={index}
          value={item.value}
          name="paymentMethod"
          label={item.label}
          disabled={isSubmitting}
          isReadOnly={deal?.isDeskingLocked}
        />
      ))}
    </div>
  );
}
