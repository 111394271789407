import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { AuthService } from '../../services/Auth';
import { Navigate, useLocation } from 'react-router-dom';

const AuthContext = createContext<any>(null);

interface AuthProviderProps {
  hostDomain: string;
  websiteDomain: string;
  children: React.ReactNode;
  allowUnauthorized?: boolean;
}

export function AuthProvider({
  hostDomain,
  websiteDomain,
  children,
  allowUnauthorized,
}: AuthProviderProps) {
  const [token, setToken] = useState<string | null | undefined>(undefined);
  const location = useLocation();

  useEffect(() => {
    AuthService.init(websiteDomain, hostDomain);
  }, [websiteDomain, hostDomain]);

  const signin = useCallback((newToken, callback) => {
    setToken(newToken);
    callback();
  }, []);

  const signout = useCallback((callback?: () => any) => {
    AuthService.signOut().then(() => {
      setToken(null);
      localStorage.removeItem('activeDealId');
      if (callback) {
        callback();
      }
    });
  }, []);

  const value = { token, signin, signout };

  useEffect(() => {
    AuthService.getToken()
      .then(newToken => {
        setToken(newToken);
      })
      .catch(() => {
        setToken(null);
      });
  }, []);

  if (token === undefined) {
    return null;
  }

  if (token === null && !allowUnauthorized) {
    <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const auth = useContext(AuthContext);
  return auth;
}
