// import ReactSwipeButton from 'react-swipe-button';

import './Summary.css';
import { useDesk, useProgramSummary } from 'src/fetches/useSWRFetch';
import { useDeals } from '../context/DealsProvider';
import { useEffect, useMemo, useState } from 'react';
import { returnTermTypeString } from 'src/constants/termType';
import { PaymentType } from 'src/types/deal';
import { SummaryItem } from './summary/SummaryItem';
import { Callout } from '../form/Callout';
import { returnUserFriendlyPrice } from 'src/constants/price';
import { returnVehicleYearMakeModel } from 'src/types/vehicle';

export function Summary() {
  const { deal, dealIsLoading, activeDealId } = useDeals();
  const { desk, deskIsError } = useDesk(activeDealId, 'desk', deal.vin);
  const { programSummary, programSummaryIsError } = useProgramSummary(activeDealId, !desk);
  const [isErrorConfirmed, setIsErrorConfirmed] = useState(false);
  const paymentType = deal?.paymentType;

  const totalFees = useMemo(() => {
    return programSummary?.feeItems?.reduce((total, feeItem) => total + feeItem.value, 0);
  }, [programSummary]);

  const totalTaxes = useMemo(() => {
    return programSummary?.taxItems?.reduce((total, feeItem) => total + feeItem.value, 0);
  }, [programSummary]);

  const taxItems = useMemo(() => {
    if (!programSummary) {
      return [];
    }
    return programSummary.taxItems.map(taxItem => {
      return {
        title: taxItem.name,
        label: returnUserFriendlyPrice(taxItem.value),
      };
    });
  }, [programSummary]);

  const feeItems = useMemo(() => {
    if (!programSummary) {
      return [];
    }
    return programSummary.feeItems.map(feeItem => {
      return {
        title: feeItem.name,
        label: returnUserFriendlyPrice(feeItem.value),
      };
    });
  }, [programSummary]);

  const term = useMemo(() => {
    return returnTermTypeString(desk?.termType || null);
  }, [desk]);

  const lender = useMemo(() => {
    return desk?.results[desk?.request?.currentCell || 0]?.lender;
  }, [desk]);

  const customerCashLabel = useMemo(() => {
    // add up downpayment and tradeinAllowanceAmount if they exist
    if (programSummary) {
      return returnUserFriendlyPrice(
        programSummary.downpayment + programSummary.tradeinAllowanceAmount
      );
    }
    return 'Loading';
  }, [programSummary]);

  const sellingPriceItem = useMemo(() => {
    return {
      title: 'Selling Price',
      label: programSummary ? returnUserFriendlyPrice(programSummary?.sellingPrice) : 'Loading',
    };
  }, [programSummary]);

  // Special case for vehicle that has MSRP but no price
  const msrpItem = useMemo(() => {
    if (deal?.price || !deal?.msrp) {
      return undefined;
    }
    return {
      title: 'MSRP',
      label: deal ? returnUserFriendlyPrice(deal?.msrp) : 'Loading',
    };
  }, [deal]);

  const totalEstFeesItem = useMemo(() => {
    return {
      title: 'Total Est. Fees',
      label: programSummary ? returnUserFriendlyPrice(totalFees) : 'Loading',
    };
  }, [programSummary, totalFees]);

  const totalEstTaxItem = useMemo(() => {
    return {
      title: 'Total Est. Tax',
      label: programSummary ? returnUserFriendlyPrice(totalTaxes) : 'Loading',
    };
  }, [programSummary, totalTaxes]);

  const downPaymentItem = useMemo(() => {
    return {
      title: 'Down Payment',
      label: programSummary ? returnUserFriendlyPrice(programSummary?.downpayment) : 'Loading',
    };
  }, [programSummary]);

  const totalTradeAllowanceItem = useMemo(() => {
    if (programSummary?.tradeinAllowanceAmount === 0) {
      return {
        title: 'Total Trade Allowance',
        label: 'Pending',
      };
    }
    return {
      title: 'Total Trade Allowance',
      label: programSummary
        ? returnUserFriendlyPrice(programSummary?.tradeinAllowanceAmount)
        : 'Loading',
    };
  }, [programSummary]);

  // To do: no rebates in retailing yet
  // const appliedRebatesItem = useMemo(() => {
  //   return {
  //     title: 'Applied Rebates',
  //     label: programSummary ? 'to do' : 'Loading',
  //   };
  // }, [programSummary]);

  const totalAmountFinancedItem = useMemo(() => {
    return {
      title: 'Total Amount Financed',
      label: programSummary ? returnUserFriendlyPrice(programSummary?.amountFinanced) : 'Loading',
    };
  }, [programSummary]);

  const interestRateItem = useMemo(() => {
    return {
      title: 'Interest Rate',
      label: programSummary
        ? `${programSummary?.rate.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })}% APR`
        : 'Loading',
    };
  }, [programSummary]);

  const dueAtSigningItem = useMemo(() => {
    return {
      title: 'Total Due at Signing',
      label: programSummary ? returnUserFriendlyPrice(programSummary?.dueAtSigning) : 'Loading',
    };
  }, [programSummary]);

  const vehicleRow = useMemo(() => {
    return {
      title: returnVehicleYearMakeModel(deal),
      subtitle: deal?.trim,
      label:
        paymentType !== PaymentType.Cash
          ? programSummary
            ? `${returnUserFriendlyPrice(programSummary?.payment)} ${term}`
            : 'Loading'
          : undefined,
      items: msrpItem
        ? [sellingPriceItem, msrpItem, totalEstFeesItem, totalEstTaxItem, dueAtSigningItem]
        : [sellingPriceItem, totalEstFeesItem, totalEstTaxItem, dueAtSigningItem],
    };
  }, [
    deal,
    programSummary,
    term,
    msrpItem,
    sellingPriceItem,
    dueAtSigningItem,
    totalEstFeesItem,
    totalEstTaxItem,
    paymentType,
  ]);

  const lenderRow = useMemo(() => {
    return {
      title: paymentType === PaymentType.Financing ? 'Financing' : 'Leasing',
      subtitle: lender,
      label: programSummary ? `${programSummary?.term} Months` : 'Loading',
      items: [totalAmountFinancedItem, interestRateItem],
    };
  }, [lender, totalAmountFinancedItem, interestRateItem, programSummary, paymentType]);

  const customerCashRow = useMemo(() => {
    return {
      title: 'Customer Cash',
      label: customerCashLabel,
      items: [downPaymentItem, totalTradeAllowanceItem],
    };
  }, [downPaymentItem, totalTradeAllowanceItem, customerCashLabel]);

  const totalEstFeesRow = useMemo(() => {
    return {
      title: 'Fees',
      label: programSummary ? returnUserFriendlyPrice(totalFees) : 'Loading',
      items: feeItems,
    };
  }, [feeItems, totalFees, programSummary]);

  const totalEstTaxRow = useMemo(() => {
    return {
      title: 'Total Est. Tax',
      label: programSummary ? returnUserFriendlyPrice(totalTaxes) : 'Loading',
      items: taxItems,
    };
  }, [totalTaxes, programSummary, taxItems]);

  const financeItems = useMemo(() => {
    return [
      vehicleRow,
      lenderRow,
      customerCashRow,
      // appliedRebatesItem,
      totalEstFeesRow,
      totalEstTaxRow,
    ];
  }, [
    vehicleRow,
    lenderRow,
    customerCashRow,
    // appliedRebatesItem,
    totalEstFeesRow,
    totalEstTaxRow,
  ]);

  const leaseItems = useMemo(() => {
    return [
      vehicleRow,
      // annualMilesItem,
      lenderRow,
      customerCashRow,
      // appliedRebatesItem,
      totalEstFeesRow,
      totalEstTaxRow,
    ];
  }, [
    vehicleRow,
    lenderRow,
    customerCashRow,
    // appliedRebatesItem,
    totalEstFeesRow,
    totalEstTaxRow,
  ]);

  const cashItems = useMemo(() => {
    return [
      vehicleRow,
      customerCashRow,
      // appliedRebatesItem,
      totalEstFeesRow,
      totalEstTaxRow,
    ];
  }, [
    vehicleRow,
    customerCashRow,
    // appliedRebatesItem,
    totalEstFeesRow,
    totalEstTaxRow,
  ]);

  const items = useMemo(() => {
    switch (paymentType) {
      case PaymentType.Cash:
        return cashItems;
      case PaymentType.Financing:
        return financeItems;
      case PaymentType.Leasing:
        return leaseItems;
      default:
        return financeItems;
    }
  }, [paymentType, cashItems, financeItems, leaseItems]);

  useEffect(() => {
    let timer;

    if (programSummaryIsError || deskIsError) {
      // We occasionally get 500 errors switching between payment types quickly
      // Set a timer to show a temporary error message until we know for sure there is an error
      timer = setTimeout(() => {
        setIsErrorConfirmed(true);
      }, 8000);
    } else {
      // Reset the isResolvingError state if there is no error
      setIsErrorConfirmed(false);
    }

    // Cleanup function to cancel the timer if the error is resolved before the timeout
    return () => clearTimeout(timer);
  }, [programSummaryIsError, deskIsError, isErrorConfirmed]);

  if (!desk || !deal || dealIsLoading) {
    return null;
  }

  if (programSummaryIsError || deskIsError) {
    return !isErrorConfirmed ? (
      <Callout title="Hold Tight..." />
    ) : (
      <Callout title="Your deal terms aren't ready yet" desc="Try coming back another time" />
    );
  }

  return (
    <div className="summary--top">
      <div className="selector--text">
        <div className="summary--list">
          {items.map((item, index) => (
            <SummaryItem {...item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}
