import { Colors } from 'src/types/colors';
import { useConfig } from '../context/config';
// import ReactSwipeButton from 'react-swipe-button';
import './Summary.css';
import { useDesk, useMe, useProgramSummary } from 'src/fetches/useSWRFetch';
import SwipeToConfirm from './SwipeToConfirm';
import { Summary } from './Summary';
import { useDeals } from '../context/DealsProvider';
import { ReviewError } from 'src/pages/deal/review/ReviewError';
import { Verify } from '../authentication/Verify';

export function Review({ handleSuccess }: { handleSuccess: () => void }) {
  const config = useConfig()!;
  const { deal, activeDealId } = useDeals();
  const { desk, deskIsError } = useDesk(activeDealId, 'desk', deal.vin);
  const { programSummaryIsError } = useProgramSummary(activeDealId, !desk);
  const { me, meIsLoading } = useMe();

  if (deskIsError || programSummaryIsError) {
    if (deskIsError.status === 409 || programSummaryIsError.status === 409) {
      return (
        <Verify
          title="You saved a payment option on this vehicle"
          subtitle="For your security, this information is protected. Please login to view your saved deal."
        />
      );
    }
    return <ReviewError />;
  }

  if (meIsLoading || !me) {
    return null;
  }

  return (
    <div className="summary">
      <Summary />
      {/* <ReactSwipeButton /> */}
      <SwipeToConfirm
        title="Swipe to File Your Offer"
        text={me.firstName + ' ' + me.lastName}
        textConfirmed={me.firstName + ' ' + me.lastName}
        color={Colors.Green1}
        onSuccess={handleSuccess}
        isShadowRoot={!config.isRetailing}
      />
      <div className="text--body-3">
        Prices may vary. Final rates need lender okay. No site content is a fixed deal. Optional
        products above have more benefits and coverage limits.
      </div>
    </div>
  );
}
