export function Lines({
  isActive,
  isTransparent,
}: {
  isActive?: boolean;
  isTransparent?: boolean;
}) {
  return (
    <svg
      className="menu-selector--lines"
      width="1"
      height="40"
      viewBox="0 0 1 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.5"
        y1="0.5"
        x2="0.499998"
        y2="39.5"
        stroke={isTransparent ? 'transparent' : isActive ? '#333333' : '#A4A4A8'}
        strokeLinecap="round"
        strokeDasharray="4 4"
      />
    </svg>
  );
}
