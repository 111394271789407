import { useCallback, useMemo, useState } from 'react';
import { ApplicantFormGroup } from '../pages/deal/payment-method/ApplicantFormGroup';
import {
  ApplicationMode,
  CreditApplicationFormDefaultValues,
  CreditApplicationSubmitBlock,
  Signer,
  returnCreditApplicationDefaultValues,
  returnSubmissionBlockData,
} from 'src/types/creditapplication-block';
import { useConfig } from 'src/components/context/config';
import { CustomerFormattedBlock, formatCustomerAddonBlock } from 'src/types/lead';
import { UnauthorizedCreditAppBlockSubmit, WidgetForm } from 'src/widgets/SpaceWidget';
import { PaymentType } from 'src/types/deal';
import toast from 'react-hot-toast';
import { useDealershipLocations } from 'src/helpers/useDealershipLocations';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { UnauthorizedSubmitCallback, useHandleSuccess } from 'src/handlers/useHandleFormSuccess';
import { useReferences } from 'src/fetches/useSWRFetch';
import {
  ReferenceFormDefaultValues,
  ReferenceSubmitBlock,
  returnReferenceSubmitBlock,
} from 'src/types/reference-block';
import { useApiFetch } from 'src/fetches/useApiFetch';

const StepMap = {
  PrimaryApplication: 'paymentPrimaryApplicationForm',
  CosignerApplication: 'paymentCosignerApplicationForm',
};

interface CreditApplicationProps {
  vin: string | null;
  handleFormCancel?: () => void;
}

interface UnauthorizedCreditApplicationProps extends CreditApplicationProps {
  references: ReferenceFormDefaultValues[];
  referencesSubmit: (
    blocks: ReferenceSubmitBlock[],
    customer?: CustomerFormattedBlock
  ) => Promise<void>;
}

export const CreditApplication = ({ vin, handleFormCancel }: CreditApplicationProps) => {
  const { references, referencesIsLoading, referencesSubmit } = useReferences(true);

  if (!references || referencesIsLoading) return null;

  return (
    <UnauthorizedCreditApplication
      vin={vin}
      handleFormCancel={handleFormCancel}
      references={references as ReferenceFormDefaultValues[]}
      referencesSubmit={referencesSubmit}
    />
  );
};

export function UnauthorizedCreditApplication({
  vin,
  handleFormCancel,
  references,
  referencesSubmit,
}: UnauthorizedCreditApplicationProps) {
  const config = useConfig()!;
  const handleSuccess: UnauthorizedSubmitCallback = useHandleSuccess(
    config.hostMessage!,
    WidgetForm.CreditApplication
  );
  const apiFetch = useApiFetch();
  const {
    sessionCustomer,
    setSessionCustomer,
    setSessionVehicle,
    setSessionLocation,
    setSessionPaymentType,
    setSessionCreditApplication,
  } = useSessionContext();

  const dealershipLocations = useDealershipLocations();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  // Begin by assuming no choice from the user
  const [applicationMode, setApplicationMode] = useState<ApplicationMode>();
  const [applicantFormData, setApplicantFormData] = useState<CreditApplicationFormDefaultValues[]>([
    {
      ...returnCreditApplicationDefaultValues(Signer.Primary, sessionCustomer),
      references,
    },
    returnCreditApplicationDefaultValues(Signer.CoSigner, undefined),
  ]);

  const [savedApplication, setSavedApplication] = useState(false);

  const formSteps = useMemo(() => {
    const array = [StepMap.PrimaryApplication];

    if (applicationMode === ApplicationMode.Joint) {
      array.push(StepMap.CosignerApplication);
    }

    return array;
  }, [applicationMode]);

  const handleApplicantCancel = () => {
    if (activeStepIndex === 0 && handleFormCancel) {
      handleFormCancel();
    } else {
      setActiveStepIndex(prev => prev - 1);
    }
  };

  // Unauthorized endpoint submit
  const handleFormSubmit = useCallback(
    async ({
      blocks,
      customer,
      location,
      dealerId,
      references,
    }: UnauthorizedCreditAppBlockSubmit) => {
      const paymentType = config.paymentOptionsConfig.disableFinancing
        ? PaymentType.Leasing
        : PaymentType.Financing;

      try {
        const { dealId, customerId }: { dealId: string; customerId: string } = await apiFetch(
          'unauthorized/credit-application',
          {
            method: 'POST',
            body: JSON.stringify({
              vin,
              paymentType,
              blocks,
              customer,
              location,
              dealerId,
            }),
          }
        );
        if (references?.length) {
          await referencesSubmit(
            references.map(ref => returnReferenceSubmitBlock(ref)),
            customer
          );
        }
        setSessionCustomer(customer);
        setSessionVehicle({ vin });
        setSessionLocation(location);
        setSessionPaymentType(paymentType);
        setSessionCreditApplication(
          blocks.map(block => ({ ...block, id: dealId || customerId, updatedAt: '' }))
        );

        await handleSuccess(customer.email, dealId, customerId);
      } catch (error: any) {
        toast.error(error.message);
        throw error.message;
      }
    },
    [
      config.paymentOptionsConfig.disableFinancing,
      apiFetch,
      vin,
      setSessionCustomer,
      setSessionVehicle,
      setSessionLocation,
      setSessionPaymentType,
      setSessionCreditApplication,
      handleSuccess,
      referencesSubmit,
    ]
  );

  const handleSubmit = async (data: CreditApplicationFormDefaultValues) => {
    // Determine if Primary or Cosigner is submitting and put application in the right slot
    const slot = formSteps[activeStepIndex] === StepMap.PrimaryApplication ? 0 : 1;
    let dealerId: any = null;

    const updatedFormData: CreditApplicationFormDefaultValues = {
      ...applicantFormData[slot],
      ...data,
    };
    // Map over form values to target that right update slot
    const updatedApplicantFormData = applicantFormData.map((app, index) => {
      if (index === slot) {
        return updatedFormData;
      }
      return app;
    });

    setApplicantFormData(updatedApplicantFormData);
    setApplicationMode(data.applicationMode);
    setSavedApplication(true);

    if (slot === 0 && data.applicationMode === ApplicationMode.Joint) {
      setActiveStepIndex(prev => prev + 1);
    } else {
      const userDeterminedLocation = updatedApplicantFormData[0].location;
      const blocks: CreditApplicationSubmitBlock[] = [
        returnSubmissionBlockData(updatedApplicantFormData[0]),
      ];

      // Ensure that the user selected Joint and actually filled one out
      if (
        applicationMode === ApplicationMode.Joint &&
        updatedApplicantFormData[1].firstName !== ''
      ) {
        blocks.push(returnSubmissionBlockData(updatedApplicantFormData[1]));
      }

      if (updatedApplicantFormData[0].dealerId) {
        dealerId = updatedApplicantFormData[0].dealerId;
      }

      const customer: CustomerFormattedBlock = formatCustomerAddonBlock(
        updatedApplicantFormData[0]
      );

      const location =
        userDeterminedLocation === '' ? config.locations[0].name : userDeterminedLocation;

      const references = updatedApplicantFormData[0].references;

      return handleFormSubmit({ blocks, customer, location, dealerId, references });
    }
  };

  return (
    <>
      {formSteps[activeStepIndex] === StepMap.PrimaryApplication ? (
        <ApplicantFormGroup
          handleApplicantFormCancel={handleApplicantCancel}
          handleApplicantFormSubmit={handleSubmit}
          type={Signer.Primary}
          payment={applicantFormData[0]}
          savedApplication={savedApplication}
          conditionalFields={{
            paymentInfoForm: () => true, // always hide
            paymentInfoAndTypeForm: () => true, // always hide
            paymentFinalizeForm: () => dealershipLocations.isMultipleLocations,
            paymentFinalizeAndLocationForm: () => !dealershipLocations.isMultipleLocations,
          }}
        />
      ) : null}

      {formSteps[activeStepIndex] === StepMap.CosignerApplication ? (
        <ApplicantFormGroup
          handleApplicantFormCancel={handleApplicantCancel}
          handleApplicantFormSubmit={handleSubmit}
          type={Signer.CoSigner}
          payment={applicantFormData[1]}
          savedApplication={false}
          //  hide if condition is true
          conditionalFields={{
            paymentInfoForm: () => true, // always hide
            paymentInfoAndCommunicationForm: () => true,
            paymentFinalizeAndLocationForm: () => true,
            paymentApplicationModeForm: () => true,
          }}
        />
      ) : null}
    </>
  );
}
