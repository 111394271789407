import { LargeLabel } from '../text/LargeLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Selector.css';
import './ValuationSelector.css';
import { faLongArrowLeft, faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Colors } from 'src/types/colors';
import { VehicleCardImage } from '../cards/vehicle/VehicleCardImage';
import { SelectorText } from './SelectorText';

interface ValuationSelectorProps {
  to: string;
  img?: string;
  title?: string;
  subtitle?: string;
  min: string | number | null; // can be a parsed queryString or a number from TradeInBlock
  max: string | number | null; // can be a parsed queryString or a number from TradeInBlock
  average: string | number | null; // can be a parsed queryString or a number from TradeInBlock
  children?: React.ReactNode;
  handleClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export default function ValuationSelector({
  to,
  img,
  title,
  subtitle,
  children,
  min,
  max,
  average,
  handleClick,
}: ValuationSelectorProps) {
  return (
    <>
      <a href={to} className="selector--default-link" onClick={handleClick}>
        {img ? <VehicleCardImage imageSrc={img} className="valuation-card--img is-empty" /> : null}
        <div className="valuation-card--right">
          <div className="valuation-card--label">
            <SelectorText title={title} subtitle={subtitle} />
          </div>

          <div className="valuation-card--features">
            {min ? (
              <LargeLabel color={Colors.Red1} text={`$${Number(min).toLocaleString('en-US')}`} />
            ) : (
              <span></span>
            )}

            <FontAwesomeIcon icon={faLongArrowLeft} className="valuation-card--icon" />
            {average ? (
              <LargeLabel
                color={Colors.Blue1}
                text={`${Number(average).toLocaleString('en-US')}`}
                className="mod-primary"
              />
            ) : null}
            <FontAwesomeIcon icon={faLongArrowRight} className="valuation-card--icon" />
            {max ? (
              <LargeLabel color={Colors.Green1} text={`$${Number(max).toLocaleString('en-US')}`} />
            ) : (
              <span></span>
            )}
          </div>
        </div>
      </a>

      {children}
    </>
  );
}
