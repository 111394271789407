import { useEffect, useMemo } from 'react';
import { Layout } from '../components/Layout';
import { ConfigContext, useConfigState } from '../components/context/config';
import { AuthProvider } from '../components/context/AuthProvider';
import SkeletonLoader from '../components/SkeletonLoader';
import { Routes, Route, Outlet } from 'react-router-dom';
import { RequireAuth } from '../components/RequireAuth';
import { LoginHeader } from '../components/LoginHeader';
import Custom404 from './404';
import Toast from '../components/Toast';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { LoginForm } from '../components/authentication/LoginForm';
import { WEB_ANALYTICS_SDK_URL } from '../constants/env';
//import { OfferMatrix } from './deal/payment-method/offer';
import { NavigateNextDealBlock } from 'src/components/navigation/NavigateNextDealBlock';

import { MobileProvider } from 'src/components/context/MobileProvider';
import { SessionProvider } from 'src/components/context/SessionProvider';
import { IndexPage } from './deal/block';
import { ItemPage } from './deal/block/item';
import { SuccessPage } from './deal/block/success';
import { ScheduleProvider } from 'src/components/context/ScheduleProvider';
library.add(faTimes);

// It's tree-shaked if added via esm (TODO: "sideEffects" - https://webpack.js.org/guides/tree-shaking/#mark-the-file-as-side-effect-free)
require('@fortawesome/fontawesome-svg-core/styles.css');
require('../styles/variables.css');
require('../styles/global.css');

const LOCALHOST_HOSTNAME = 'localhost';
// stage 4 currently - can be used safely in the ES version
// eslint-disable-next-line no-undef
const hostDomain = globalThis?.location?.hostname ?? LOCALHOST_HOSTNAME;

export default function App() {
  const config = useConfigState({});

  const dealershipLogoUrl = useMemo(
    () =>
      hostDomain !== LOCALHOST_HOSTNAME
        ? config?.websiteDomain
          ? `https://${config.websiteDomain}/wp-json/space-multisite/v1/api/?logo=true`
          : null
        : '/logo.png',
    [config?.websiteDomain]
  );

  // const UnauthenticatedLayout = () => <Layout dealershipLogoUrl={dealershipLogoUrl} />;

  const AuthenticatedLayout = () => (
    <RequireAuth>
      <Layout dealershipLogoUrl={dealershipLogoUrl} />
    </RequireAuth>
  );

  const AppBlockRoutes = (
    <Route path=":block" element={<Outlet />}>
      <Route index element={<IndexPage />} />
      <Route path="success" element={<SuccessPage />} />
      <Route path=":blockId" element={<ItemPage />} />
    </Route>
  );

  useEffect(() => {
    if (config?.websiteDomain && hostDomain !== LOCALHOST_HOSTNAME) {
      const favIconEl = document.getElementById('favicon') as HTMLLinkElement;
      favIconEl.href = `https://${config.websiteDomain}/wp-json/space-multisite/v1/api/?favicon=true`;
    }
  }, [config?.websiteDomain]);

  useEffect(() => {
    const webAnalyticsScriptId = 'web-analytics-sdk';
    const existingScript = document.getElementById(webAnalyticsScriptId);
    if (existingScript || hostDomain === LOCALHOST_HOSTNAME) {
      return;
    }

    const script = document.createElement('script');
    script.id = webAnalyticsScriptId;
    script.async = true;
    script.src = WEB_ANALYTICS_SDK_URL;

    document.body.appendChild(script);
  }, []);

  if (!config) {
    return <SkeletonLoader />;
  }

  return (
    <>
      <ConfigContext.Provider value={config}>
        <Toast />
        <AuthProvider
          websiteDomain={config.websiteDomain}
          hostDomain={hostDomain}
          allowUnauthorized={false}
        >
          <ScheduleProvider>
            <MobileProvider>
              <SessionProvider>
                <Routes>
                  {['sign-in', 'login'].map((path, index) => (
                    <Route
                      key={index}
                      path={path}
                      element={
                        <div className="login-page">
                          <LoginHeader dealershipLogoUrl={dealershipLogoUrl} />
                          <div className="main">
                            <LoginForm formNavigationClass="block-container--static-nav" />
                          </div>
                        </div>
                      }
                    />
                  ))}
                  <Route element={<AuthenticatedLayout />}>
                    <Route path="/" element={<NavigateNextDealBlock />} />
                  </Route>
                  <Route path="deals" element={<AuthenticatedLayout />}>
                    <Route path=":dealId" element={<Outlet />}>
                      <Route index element={<NavigateNextDealBlock />} />
                      {AppBlockRoutes}
                    </Route>
                  </Route>
                  <Route element={<AuthenticatedLayout />}>{AppBlockRoutes}</Route>
                  <Route path="*" element={<Custom404 />} />
                </Routes>
              </SessionProvider>
            </MobileProvider>
          </ScheduleProvider>
        </AuthProvider>
      </ConfigContext.Provider>
    </>
  );
}
